import {useApiInterface} from "../data/useApiInterface";
import {useMessagesContext} from "./Messages";
import {useEffect, useState} from "react";
import {TUser} from "../data/datatypes";
import {Button, Stack} from "@mui/material";
import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import {Delete, Edit} from "@mui/icons-material";
import dayjs from "dayjs";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {UserEdit} from "./UserEdit";
import {UserDeleteConfirmDialog} from "./UserDeleteConfirmDialog";

export const UserManager = () => {
    const {apiGet} = useApiInterface();
    const {setBusy, displayErrorMessage} = useMessagesContext();
    const [userList, setUserList] = useState<TUser[]>([]);
    const [editorOpen, setEditorOpen] = useState(false);
    const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState<TUser | undefined>();
    useEffect(() => {
        setBusy(true);
        apiGet("/userManager/users")
            .then(data => {
                setUserList(data);
            })
            .catch(e => displayErrorMessage(e.message()))
            .finally(() => setBusy(false));
    }, []);

    const userManagerGridColumns: GridColDef[] = [
        {field: "userId", headerName: "ID", width: 100},
        {field: "login", headerName: "Login", flex: 2},
        {field: "name", headerName: "Name", flex: 2},
        {
            field: 'staff', headerName: 'Staff?', width: 150,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                return params.value ? "Yes" : "No"
            },
        },
        {
            field: 'lastLogin',
            headerName: 'Last Login',
            type: "dateTime",
            width: 250,
            valueGetter: ({value}) => value && dayjs(value).toDate()
        },
        {
            field: 'actions', headerName: 'Actions', width: 100, sortable: false,
            renderCell: (params) => {
                const actions = <><Button onClick={e => {
                    e.currentTarget.blur();
                    const user = userList.filter(v => v.userId === params.id);
                    if (user) {
                        setSelectedUser(user[0])
                        setEditorOpen(true);
                    }
                }} sx={{p: 0, minWidth: 20}} title="Edit User"><Edit/></Button><Button onClick={e => {
                    e.currentTarget.blur();
                    const user = userList.filter(v => v.userId === params.id);
                    if (user) {
                        setSelectedUser(user[0])
                        setDeleteConfirmOpen(true);
                    }
                }} sx={{p: 0, minWidth: 20}} title="Delete User"><Delete/></Button></>;
                return <Stack direction="row" gap={2}>{actions}</Stack>;
            }
        },
    ];


    return (<>
        <Stack direction="column" alignItems="flex-end" gap={3} padding={2}>
            <Button variant="contained" onClick={() => {
                setSelectedUser(undefined);
                setEditorOpen(true);
            }}>ADD USER</Button>
            <DataGridPro
                initialState={{
                    sorting: {
                        sortModel: [{field: 'login', sort: 'asc'}],
                    },
                }}
                sx={{
                    width: "100%",
                    '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                }}
                density="compact"
                getRowId={(row) => row.userId}
                disableColumnMenu autoHeight hideFooter disableRowSelectionOnClick
                rows={userList}
                columns={userManagerGridColumns}/>
        </Stack>
        <UserEdit open={editorOpen} setOpen={setEditorOpen} user={selectedUser} userList={userList} setUserList={setUserList}/>
        <UserDeleteConfirmDialog open={deleteConfirmOpen} setOpen={setDeleteConfirmOpen} user={selectedUser} userList={userList} setUserList={setUserList}/>
    </>)
}
