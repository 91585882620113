import {TDestination, TSource} from "./datatypes";
import {useApiInterface} from "./useApiInterface";
import {ErrorData} from "./APIError";
import {useDataContext} from "./Data";

export const useSource = (channelName: string | undefined, destination?: TDestination): {
    source?: TSource,
    lockSource?: ((state: boolean, reason: string) => Promise<void>),
    goLive?: (() => Promise<void>),
    inControl?: { channelName: string, key: string },
    toggleControl?: () => void,
    setPoppedOut?: (state: boolean) => void,
} => {
    const {sources, inControl, setInControl, channelsPoppedOut, setChannelsPoppedOut} = useDataContext();

    const {apiPost} = useApiInterface();
    if (channelName === undefined || sources[channelName] === undefined) return {};

    const lockSource = (state: boolean, reason: string): Promise<void> => {
        return new Promise((resolve, reject) => {
            apiPost("/source/" + channelName + "/" + (state ? "lock" : "unlock"), {reason: reason})
                .then((sourceData: TSource) => {
                    sources[channelName] = sourceData;
                    resolve();
                })
                .catch((err: ErrorData) => {
                    reject(err);
                });
        });
    }
    const goLive = (): Promise<void> => new Promise((resolve, reject) => {
            apiPost("/source/" + channelName + "/" + destination?.destinationChannel + "/golive")
                .then(() => {
                    resolve();
                })
                .catch((err: ErrorData) => {
                    reject(err);
                })
        }
    );

    const toggleControl = () => {
        setInControl(inControl !== undefined && inControl.channelName === channelName ? undefined : {
            channelName: channelName,
            key: sources[channelName].key
        });
    }
    const setPoppedOut = (state: boolean) => {
        if (state) {
            if (channelsPoppedOut.includes(channelName)) return;
            setChannelsPoppedOut(chnls => [...chnls, channelName]);
        } else {
            if (!channelsPoppedOut.includes(channelName)) return;
            setChannelsPoppedOut(channelsPoppedOut.filter(v => v != channelName));
        }
        console.log("Setting Popped Out", channelsPoppedOut);
    };

    return {
        source: sources[channelName],
        lockSource,
        goLive,
        inControl,
        toggleControl,
        setPoppedOut,
    }
};
