import React, {useEffect} from 'react';
import {Header} from "./components/Header";
import {Footer} from "./components/Footer";
import {Box, Stack} from "@mui/material";
import {MemoryRouter, Route, Routes} from "react-router-dom";
import {MainContent} from "./components/MainContent";
import {Login} from "./components/Login";
import {useDataContext} from "./data/Data";
import {useApiInterface} from "./data/useApiInterface";

function App() {
    const {sessionId} = useDataContext();
    const {refreshSession} = useApiInterface();
    useEffect(refreshSession, []);
    return (
        <MemoryRouter>
            <Header/>
            {sessionId &&
                <Stack flexGrow={1} direction="column">
                    <Routes>
                        <Route index element={<MainContent/>}/>
                    </Routes>
                </Stack>
            }
            {sessionId === "" &&
                <Routes>
                    <Route index element={<Login/>}/>
                </Routes>}
            {sessionId === undefined && <Box sx={{flexDirection: "column", flexGrow: 1}}/>}
            <Footer/>
        </MemoryRouter>
    );
}

export default App;


/*


 */