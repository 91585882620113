import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import axios from "axios";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import dayjs from "dayjs";
import {useDataContext} from "../data/Data";

export const SessionWarning = ({ showWarning, setShowWarning, lastActivity }: { showWarning: boolean; setShowWarning: Dispatch<SetStateAction<boolean>>; lastActivity: number }) => {
  const { idleTimeout, timeoutWarning, clearSession } = useDataContext();
  const [secondsToLogout, setSecondsToLogout] = useState(timeoutWarning);
  const [showMe, setShowMe] = useState(true); //Used to have immediate user repsonse to continue button

  useEffect(() => {
    if (!showWarning) {
      setShowMe(true);
      setSecondsToLogout(timeoutWarning);
      return;
    }
    const interval = setInterval(() => {
      const secssince = dayjs().unix() - lastActivity;
      setSecondsToLogout(idleTimeout - secssince);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [showWarning]);

  const logout = () => {
    setShowWarning(false);
    clearSession();
  };

  const continueSess = () => {
    setShowMe(false);
    axios.get("/session").then(() => {
      return;
    });
  };

  return (
    <>
      <Dialog open={showWarning && showMe} disableEscapeKeyDown>
        <DialogTitle>Session timing out in {secondsToLogout} seconds</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography lineHeight={1.3}>
              For security purposes your session is about to close due to inactivity. Would you like to logout or continue this session?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "space-between", padding: "0px 25px", paddingTop: 1, paddingBottom: 2 }}>
          <Stack justifyContent="space-between" direction="row" width="100%">
            <Button variant="outlined" color="error" onClick={logout}>
              LOGOUT
            </Button>
            <Button variant="contained" onClick={continueSess}>
              CONTINUE
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
};
