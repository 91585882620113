export interface IConfig {
    ENABLE_LEGACY_SITE_LINK: boolean;
    ENABLE_LEGACY_SUPPORT_CHAT: boolean;
    SPACELINK_SUPPORT_CHAT_URL: string;
    SPACELINK_SUPPORT_CHAT_REGKEY: string;
    SPECIAL_GROUP: string;
}

export const config: IConfig = {
    ENABLE_LEGACY_SITE_LINK: false,
    ENABLE_LEGACY_SUPPORT_CHAT: false,
    SPACELINK_SUPPORT_CHAT_URL: "https://supportchat.spacelink.com",
    SPACELINK_SUPPORT_CHAT_REGKEY: "abdd2692-9ce2-448c-9f99-5aaaef634888",
    SPECIAL_GROUP: "",
};

export const setConfig = (data: IConfig) => {
    config.ENABLE_LEGACY_SITE_LINK = data.ENABLE_LEGACY_SITE_LINK;
    config.ENABLE_LEGACY_SUPPORT_CHAT = data.ENABLE_LEGACY_SUPPORT_CHAT;
    config.SPACELINK_SUPPORT_CHAT_URL = data.SPACELINK_SUPPORT_CHAT_URL;
    config.SPACELINK_SUPPORT_CHAT_REGKEY = data.SPACELINK_SUPPORT_CHAT_REGKEY;
    config.SPECIAL_GROUP = data.SPECIAL_GROUP;
};
