import {createTheme, ThemeOptions} from '@mui/material/styles';

export const themeOptions: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#e74d15',
        },
        secondary: {
            main: '#29aae1',
        },
        text: {
            primary: '#ffffff',
        },
    },
    typography: {
        fontFamily: 'Fira Sans, Roboto, Helvetica, Arial, sans-serif',
        fontSize: 12,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    borderRadius: 0
                },
                outlined: {
                    borderRadius: 0
                },
            }
        }
    }
};

export const slcolor = {
    darkblue: "#001a39",
    lightblue: "#29aae1",
    darkgray: "#464646",
    lightgray: "#a7a7a7",
    orange: "#e74d15",
    red: "#ff0000",
};


export const theme = createTheme(themeOptions);
