import {createContext, Dispatch, ReactNode, RefObject, SetStateAction, useContext, useEffect, useState} from "react";
import {useDataContext} from "./Data";
import {siteConfig} from "../config";
import dayjs from "dayjs";
import {TAlert} from "./datatypes";

export type TPacket = {
    id: string;
    type: string;
    action?: string;
    actionData?: any;
    dataType?: string;
    data?: any;
    errorMessage?: string;
    errorData?: any;
};

const isPacket = (object: any): object is TPacket => {
    return ('id' in object) && ('type' in object);
}

export const PacketActions = {
    SENDSERVERDATA: "sendserverdata",
    SUBSCRIBECHANNEL: "subscribechannel",
    UNSUBSCRIBECHANNEL: "unsubscribechannel",
    SYNCCHANNELS: "syncchannels",
    CLOSESESSION: "closesession",
    NEWCHATMESSAGE: "newchatmessage",
    ENABLEEXTERNALPUBLISHDATA: "enableexternalpublishdata",
    UPDATEALERT: "updatealert",
}

export const PacketDataTypes = {
    SERVERDATA: "serverdata",
    SESSIONDATA: "sessiondata",
    USERLISTDATA: "userlistdata",
    USERGROUPLISTDATA: "usergrouplistdata",
    CHATMESSAGESDATA: "chatmessagesdata",
    EXTERNALPUBLISHDATA: "externalpublishdata",
    ALERTUPDATE: "alertupdate",
}

export const PacketType = {
    DATAPACKET: "datapacket",
    ERRORPACKET: "errorpacket",
    ACTIONPACKET: "actionpacket",
}

const WSErrorCodeString: { [key: number]: string } = {
    1000: "Normal Closure",
    1001: "Going Away",
    1002: "Protocol error",
    1003: "Unsupported Data",
    1004: "Reserved",
    1005: "No Status Rcvd",
    1006: "Abnormal Closure",
    1007: "Invalid frame payload data",
    1008: "Policy Violation",
    1009: "Message Too Big",
    1010: "Mandatory Ext.",
    1011: "Internal Error",
    1012: "Service Restart",
    1013: "Try Again Later",
    1014: "Bad Gateway",
    1015: "TLS handshake",
}

const options = {
    reconnectInterval: 2000,
    debugLevel: 1,
};
const socketHost = process.env.NODE_ENV !== "production" ? "localhost:5002" : window.location.host;

let revert: (state: boolean) => void;
let isReverted = false;
let isForceJsPull = false;
let jwtToken = "";
let sessionId = "";
let socket: WebSocket;
let subscribedChannels: { [channelId: number]: { channelName: string; mjpegName: string; img: Set<RefObject<HTMLImageElement>> } } = {};
let timeConnectionLost = 0;
let doNotReconnect = false;
let lastImagePacketReceived = 0;
let syncChannelsIntervalId = 0;
let routePacket: (packet: TPacket) => void;
export const WSInterfaceContext = createContext<IWSInterfaceContext | undefined>(undefined);
export const useWSInterface = (): IWSInterfaceContext => {
    const context = useContext(WSInterfaceContext);
    if (context === undefined) throw new Error("useWSInterface must be within WSInterfaceProvider");
    return context;
}

const WSInterface = ({children, wsClose, setWsClose}: { children: ReactNode; wsClose: boolean; setWsClose: Dispatch<SetStateAction<boolean>> }) => {
    const dataCtxt = useDataContext();
    const [reverted, setReverted] = useState(false);
    useEffect(() => {
        revert = setReverted;
        routePacket = dataCtxt.routePacket;
        jwtToken = dataCtxt.token;
        if (dataCtxt.userData) {
            isForceJsPull = dataCtxt.userData.forceJSPull;
        }
        if (!dataCtxt.sessionId) return;
        if (sessionId === "" && dataCtxt.sessionId) {
            sessionId = dataCtxt.sessionId;
            if (dataCtxt.userData?.forceJSPull) {
                console.log("Forcing JS Pull")
                setReverted(true);
            } else {
                start();
            }
        } else {
            sessionId = dataCtxt.sessionId;
        }
    }, [dataCtxt.token, dataCtxt.sessionId]);
    useEffect(() => {
        isReverted = reverted;
        console.log("REVERT CHANGED TO " + reverted);
        startJSPull();
    }, [reverted]);
    useEffect(() => {
        if (wsClose) {
            stop();
            setWsClose(false);
        }
    }, [wsClose]);
    return (
        <WSInterfaceContext.Provider
            value={{
                subscribeChannel,
                unSubscribeChannel,
                unSubscribeAll,
                stop,
                reverted,
                sendChatMessage,
                enableExternalPublishData,
                setAlert,
            }}>
            {children}
        </WSInterfaceContext.Provider>
    );
}

export interface IWSInterfaceContext {
    subscribeChannel: (channelId: number, channelName: string, mjpegName: string, ref: RefObject<HTMLImageElement>) => void,
    unSubscribeChannel: (channelId: number, ref: RefObject<HTMLImageElement>) => void,
    unSubscribeAll: () => void,
    stop: () => void,
    reverted: boolean,
    sendChatMessage: (message: string) => void,
    enableExternalPublishData: (state: boolean) => void,
    setAlert: (alert: TAlert) => void,
}

export default WSInterface;

const enableExternalPublishData = (state: boolean) => {
    debug(1, (state ? "Enabling" : "Disabling") + " external publishing data feed")
    send(makeActionPacket(PacketActions.ENABLEEXTERNALPUBLISHDATA, state));
}

const setAlert = (alert: TAlert) => {
    send(makeActionPacket(PacketActions.UPDATEALERT, alert));
}

const sendChatMessage = (message: string) => {
    if (!isReverted) send(makeActionPacket(PacketActions.NEWCHATMESSAGE, message));
}

const subscribeChannel = (channelId: number, channelName: string, mjpegName: string, ref: RefObject<HTMLImageElement>) => {
//    if (ref.current === null) return;
    debug(1, "Subscribing channel", channelId);
    if (subscribedChannels[channelId] == undefined) {
        subscribedChannels[channelId] = {channelName: channelName, mjpegName: mjpegName, img: new Set<RefObject<HTMLImageElement>>()};
    }
    subscribedChannels[channelId].img.add(ref);
    if (isReverted) {
        jsImgPull(mjpegName, channelId).then(() => {
            return
        });
    } else {
        send(makeActionPacket(PacketActions.SUBSCRIBECHANNEL, {channelId: channelId, channelName: channelName}));
    }
};
const unSubscribeChannel = (channelId: number, ref: RefObject<HTMLImageElement>) => {
    if (subscribedChannels[channelId] === undefined) return;
    debug(1, "Unsubscribing channel", channelId);
    if (subscribedChannels[channelId].img.has(ref)) {
        subscribedChannels[channelId].img.delete(ref)
    }
    if (subscribedChannels[channelId].img.size == 0) {
        delete (subscribedChannels[channelId]);
    }
    send(makeActionPacket(PacketActions.UNSUBSCRIBECHANNEL, channelId));
};
const unSubscribeAll = () => {
    debug(1, "Unsubscribing all channels")
    Object.entries(subscribedChannels).forEach(([, v]) => {
        v.img.clear()
    })
    subscribedChannels = {};
    lastImagePacketReceived = 0;
    syncChannels();
};

const syncChannels = () => {
    debug(3, "syncChannels");
    const channels: { [channelId: number]: string } = {};
    for (const [id, ch] of Object.entries(subscribedChannels)) {
        channels[parseInt(id)] = ch.channelName
    }
    send(makeActionPacket(PacketActions.SYNCCHANNELS, channels));
    if (Object.keys(subscribedChannels).length === 0) {
        lastImagePacketReceived = 0; //No channels subscribed to.  Reset timestamp so it will not revert
    }
    const ct = new Date().getTime() / 1000;
    if (lastImagePacketReceived != 0 && (ct - lastImagePacketReceived) > 15 && !isReverted) {
        debug(1, "Lost video packets. Reverting to JS pull and reconnecting");
        revert(true);
    }
};
const stop = () => {
    if (isForceJsPull) return;
    sessionId = "";
    clearInterval(syncChannelsIntervalId);
    lastImagePacketReceived = 0;
    Object.entries(subscribedChannels).forEach(([, v]) => {
        v.img.clear()
    })
    subscribedChannels = {};
    syncChannels();
    doNotReconnect = true;
    socket.close();
};
const send = (data: any) => {
    if (isForceJsPull) return;
    if (!socket) {
        connect();
        const iid = setInterval(function () {
            if (socket.readyState === 1) {
                clearInterval(iid);
                debug(3, "Sending packet", data);
                socket.send(JSON.stringify(data));
            }
        }, 100);
    } else if (socket.readyState === 1) {
        debug(3, "Sending packet", data);
        socket.send(JSON.stringify(data));
    } else {
        setTimeout(function () {
            if (socket.readyState !== 1) return;
            debug(3, "Sending packet", data);
            socket.send(JSON.stringify(data));
        }, 100);
    }
};

const start = () => {
    subscribedChannels = {};
    timeConnectionLost = 0;
    doNotReconnect = false;
    lastImagePacketReceived = 0;
    syncChannelsIntervalId = 0;
    connect();
};

const connect = () => {
    if (!window["WebSocket"]) {
        log("WebSockets unavailable.");
        return;
    }
    if (!jwtToken) return;
    if (socket && socket.readyState == socket.OPEN) return;
    const wsurl = new URL((window.location.protocol == "http:" ? "ws" : "wss") + "://" + socketHost + "/ws");
    wsurl.searchParams.append("token", jwtToken);
    log("Connecting to web socket")
    socket = new WebSocket(wsurl.href);
    socket.onopen = onOpen;
    socket.onmessage = onMessage;
    socket.onclose = onClose;
    socket.onerror = onError;
};

const onOpen = () => {
    log("WebSocket connected");
    timeConnectionLost = 0;
    syncChannels()
    clearInterval(syncChannelsIntervalId);
    syncChannelsIntervalId = window.setInterval(syncChannels, 1000);
};

const onClose = (event: CloseEvent) => {
    log(`WebSocket closed with code ${event.code} (${WSErrorCodeString[event.code]})`);
    reConnect();
};

const onError = (event: Event) => {
    if (event.target instanceof WebSocket && event.target.readyState !== 1) return
    log("WebSocket conection error:", event);
    reConnect();
};

const onMessage = (event: MessageEvent) => {
    const data = event.data;
    if (data instanceof Blob) {
        const channelData = data.slice(0, 1);
        const imageData = data.slice(1);
        channelData.arrayBuffer()
            .then((buffer: ArrayBuffer) => {
                const channel: number = (new Int8Array(buffer))[0];
                if (subscribedChannels[channel] === undefined) return;
                debug(5, "setting img buffer", {channel: channel});
                if (isReverted) revert(false);
                lastImagePacketReceived = new Date().getTime() / 1000;
                const objurl = URL.createObjectURL(imageData);
                subscribedChannels[channel].img.forEach((ref) => {
                    if (ref.current == null) return;
                    ref.current.src = objurl;
                    ref.current.onload = () => URL.revokeObjectURL(objurl);
                });
            });
    } else {
        let packet: any;
        try {
            packet = JSON.parse(data);
        } catch (e) {
            return;
        }
        if (isPacket(packet)) {
            routePacket(packet as TPacket)
        } else {
            debug(1, "Unknown data received on websocket", packet, data)
        }
    }
};

const reConnect = () => {
    if (doNotReconnect) return;
    clearInterval(syncChannelsIntervalId);
    debug(1, "reConnect", {timeConnectionLost: timeConnectionLost});
    if (timeConnectionLost === 0) {
        timeConnectionLost = new Date().getTime() / 1000;
        log(`Reconnecting in ${options.reconnectInterval / 1000} seconds...`);
    } else {
        const duration = (new Date().getTime() / 1000) - timeConnectionLost;
        if (duration > 5 && !isReverted) {
            debug(1, "Connection lost greater than 5 seconds.  Reverting to JS pull.");
            revert(true)
        }
    }
    setTimeout(connect, options.reconnectInterval);
};


const makeActionPacket = (action: string, actionData: any): TPacket => {
    return {
        id: create_UUID(),
        type: PacketType.ACTIONPACKET,
        action: action,
        actionData: actionData,
    };
};

const create_UUID = () => {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

const debug = (level: number, ...optionalParams: any[]) => {
    if (level > options.debugLevel) return;
    console.debug("WSINTERFACE", optionalParams);
}
const log = (...optionalParams: any[]) => {
    console.log("WSINTERFACE", optionalParams);
}


const startJSPull = () => {
    Object.entries(subscribedChannels).forEach(([channelId, v]) => {
        jsImgPull(v.mjpegName, Number(channelId)).then(() => null);
    });
}

const jsImgPull = async (mjpegName: string, channelId: number) => {
    if (!subscribedChannels[channelId]) return;
    const refs = subscribedChannels[channelId].img;
    while (isReverted && subscribedChannels[channelId]) {
        const imageData = await fetch(siteConfig.jsPullUrl + "?name=" + mjpegName + "&val=" + dayjs().unix());
        const blob = await imageData.blob();
        const objurl = URL.createObjectURL(blob);
        refs.forEach(ref => {
            if (ref.current == null) return;
            ref.current.src = objurl;
            ref.current.onload = () => URL.revokeObjectURL(objurl);
        });
    }
};
















