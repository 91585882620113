import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {IUserEditData, TUser} from "../data/datatypes";
import * as yup from "yup";
import YupPassword from "yup-password";
import {useMessagesContext} from "./Messages";
import {useApiInterface} from "../data/useApiInterface";
import {useFormik} from "formik";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, IconButton, InputAdornment, TextField, Typography} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {HStack, VStack} from "./SiteTools";
import {ErrorData} from "../data/APIError";
import {useDataContext} from "../data/Data";

YupPassword(yup);

const passwordField = () =>
    yup.string()
        .min(8, "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special")
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character");


export const UserEdit = ({open, setOpen, user, userList, setUserList}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    user: TUser | undefined;
    userList: TUser[];
    setUserList: Dispatch<SetStateAction<TUser[]>>;
}) => {
    const {userData} = useDataContext();
    const {setBusy, displayErrorMessage} = useMessagesContext();
    const {apiGet, apiPut} = useApiInterface();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [userEditData, setUserEditData] = useState<IUserEditData>();
    const [readyToOpen, setReadyToOpen] = useState(false);
    const closeDialog = () => {
        formik.resetForm();
        setOpen(false);
    };
    const newUser = user == undefined;
    useEffect(() => {
        setReadyToOpen(false);
        if (!open) return;
        setBusy(true);
        setUserEditData(undefined);
        apiGet("/userManager/user/" + (newUser ? 0 : user.userId) + "/userEditData")
            .then((data: IUserEditData) => {
                setUserEditData(data);
                formik.resetForm();
                formik.setValues({
                    login: data.user.login,
                    name: data.user.name,
                    email: data.user.email,
                    password: "",
                    confirm: "",
                    staff: data.user.staff,
                    camzoneStaff: data.user.camzoneStaff,
                    editUsers: data.user.editUsers,
                    externalPublish: data.user.externalPublish,
                    usageStats: data.user.usageStats,
                    recordings: data.user.recordings,
                    setHome: data.user.setHome,
                    forceJSPull: data.user.forceJSPull,
                    stealthLogin: data.user.stealthLogin,
                    enabled: data.user.enabled,
                }, false);
            })
            .catch(e => {
                displayErrorMessage(e.message);
                setBusy(false);
            })
            .finally(() => {
                setReadyToOpen(true);
                setBusy(false);
            });
        return () => {
            setUserEditData(undefined);
        }
    }, [open]);

    const validationSchema = yup.object({
        login: yup.string().required().min(3).matches(/^\S*$/).lowercase(),
        name: yup.string().required().min(3),
        email: yup.string().required().email(),
        password: (newUser ? passwordField().required() : passwordField()),
        confirm: yup
            .string()
            .oneOf([yup.ref("password"), undefined], "Passwords must match"),
    });
    const formik = useFormik({
        initialValues: {
            login: "",
            name: "",
            email: "",
            password: "",
            confirm: "",
            staff: false,
            camzoneStaff: false,
            editUsers: false,
            externalPublish: false,
            usageStats: false,
            recordings: false,
            setHome: false,
            forceJSPull: false,
            stealthLogin: false,
            enabled: false,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const userData: TUser = {
                userId: newUser ? 0 : user.userId,
                login: values.login,
                email: values.email,
                name: values.name,
                enabled: values.enabled,
                staff: values.staff,
                camzoneStaff: values.camzoneStaff,
                editUsers: values.editUsers,
                recordings: values.recordings,
                externalPublish: values.externalPublish,
                usageStats: values.usageStats,
                lastLogin: null,
                setHome: values.setHome,
                mediaManager: false,
                channelSettings: false,
                forceJSPull: values.forceJSPull,
                password: values.password,
                admin: false,
                stealthLogin: values.stealthLogin,
            }
            const editData = {
                user: userData,
                sourcePermissions: userEditData?.sourcePermissions ?? {},
                destinationPermissions: userEditData?.destinationPermissions ?? {},
                estopPermissions: userEditData?.estopPermissions ?? {},
            }
            setBusy(true);
            apiPut("/userManager/user", editData)
                .then(data => {
                    const u: TUser = data;
                    const ul = userList.filter(v => v.userId != u.userId);
                    ul.push(u);
                    setUserList(ul);
                    setOpen(false);
                    return;
                })
                .catch((ed: ErrorData) => {
                    console.log("ERRORDATA", ed);
                    if (ed.code === "E_FIELDVALIDATION" && ed.details) {
                        Object.entries(ed.details.fields).forEach(([fieldName, error]) => {
                            formik.setFieldError(fieldName, (error as string));
                        });
                    } else {
                        displayErrorMessage(ed.message);
                    }
                })
                .finally(() => setBusy(false));
        },
    });

    const modifyUserEditData = (modFunc: (ued: IUserEditData) => IUserEditData) => {
        if (!userEditData) return;
        const ued: IUserEditData = {...userEditData};
        const nued = modFunc(ued);
        setUserEditData(nued);
    };

    const groupViewStatus = (groupName: string): [number, number] => {
        if (!userEditData) return [0, 0];
        const groupChannels = Array.from(new Set(Object.entries(userEditData.groups[groupName]).map(([, g]) => g.sources).flat().sort())).filter(cn => userEditData.sources[cn] != undefined);
        const trues: [number, number] = [0, 0];
        groupChannels.forEach(v => {
            if (!userEditData.sourcePermissions[v]) return;
            if (userEditData.sourcePermissions[v].view) trues[0]++;
            if (userEditData.sourcePermissions[v].control) trues[1]++;
        });
        trues[0] = trues[0] == 0 ? 0 : trues[0] == groupChannels.length ? 1 : -1;
        trues[1] = trues[1] == 0 ? 0 : trues[1] == groupChannels.length ? 1 : -1;
        return trues;
    }
    const groupGoLiveStatus = (groupName: string): number => {
        if (!userEditData) return 0;
        const destChannels = Object.keys(userEditData.groups[groupName]).sort();
        if (destChannels[0] == "") return 0;
        let trues = 0;
        destChannels.forEach(v => {
            if (userEditData.destinationPermissions[v]) trues++;
        });
        if (trues != 0) {
            trues = trues == destChannels.length ? 1 : -1;
        }
        return trues;
    }
    const setGroupViewStatus = (groupName: string, checked: boolean) => {
        modifyUserEditData(ued => {
            const groupChannels = Array.from(new Set(Object.entries(ued.groups[groupName]).map(([, g]) => g.sources).flat().sort())).filter(cn => ued.sources[cn] != undefined);
            groupChannels.forEach(v => {
                if (ued.sourcePermissions[v] == undefined) ued.sourcePermissions[v] = {channel: v, userid: newUser ? 0 : ued.user.userId, view: false, control: false};
                ued.sourcePermissions[v].view = checked
            });
            return ued;
        });
    };
    const setGroupControlStatus = (groupName: string, checked: boolean) => {
        modifyUserEditData(ued => {
            const groupChannels = Array.from(new Set(Object.entries(ued.groups[groupName]).map(([, g]) => g.sources).flat().sort())).filter(cn => ued.sources[cn] != undefined);
            groupChannels.forEach(v => {
                if (ued.sourcePermissions[v] == undefined) ued.sourcePermissions[v] = {channel: v, userid: newUser ? 0 : ued.user.userId, view: false, control: false};
                ued.sourcePermissions[v].control = checked
            });
            return ued;
        });
    };
    const setGroupGoliveStatus = (groupName: string, checked: boolean) => {
        modifyUserEditData(ued => {
            const destChannels = Object.keys(ued.groups[groupName]).sort();
            if (destChannels[0] == "") return ued;
            destChannels.forEach(v => {
                ued.destinationPermissions[v] = checked
            });
            return ued;
        });
    };

    const estopSitesStatus = () => {
        if (!userEditData) return [0, 0];
        const numSites = Object.entries(userEditData.estopPermissions).length;
        const viewChecked = Object.entries(userEditData.estopPermissions).filter(([, p]) => p.view).length;
        const controlChecked = Object.entries(userEditData.estopPermissions).filter(([, p]) => p.control).length;
        return [viewChecked === numSites ? 1 : (viewChecked === 0 ? 0 : -1), controlChecked === numSites ? 1 : (controlChecked === 0 ? 0 : -1)]
    }
    const setEStopSitesViewStatus = (checked: boolean) => {
        modifyUserEditData(ued => {
            Object.entries(ued.estopPermissions).forEach(([, p]) => p.view = checked);
            return ued;
        });
    }
    const setEStopSitesControlStatus = (checked: boolean) => {
        modifyUserEditData(ued => {
            Object.entries(ued.estopPermissions).forEach(([, p]) => p.control = checked);
            return ued;
        });
    }

    const setChannelViewState = (channelName: string, state: boolean) => {
        modifyUserEditData(ued => {
            if (ued.sourcePermissions[channelName] == undefined) ued.sourcePermissions[channelName] = {channel: channelName, userid: newUser ? 0 : ued.user.userId, view: false, control: false};
            ued.sourcePermissions[channelName].view = state;
            return ued;
        });
    }
    const setChannelControlState = (channelName: string, state: boolean) => {
        modifyUserEditData(ued => {
            if (ued.sourcePermissions[channelName] == undefined) ued.sourcePermissions[channelName] = {channel: channelName, userid: newUser ? 0 : ued.user.userId, view: false, control: false};
            ued.sourcePermissions[channelName].control = state;
            return ued;
        });
    }
    const setDestinationGoLiveState = (channelName: string, state: boolean) => {
        modifyUserEditData(ued => {
            ued.destinationPermissions[channelName] = state
            return ued;
        });
    }
    const setEstopViewState = (estopId: string, checked: boolean) => {
        modifyUserEditData(ued => {
            ued.estopPermissions[estopId].view = checked;
            return ued;
        });
    }
    const setEstopControlState = (estopId: string, checked: boolean) => {
        modifyUserEditData(ued => {
            ued.estopPermissions[estopId].control = checked;
            return ued;
        });
    }


    const camGroupStack = (groupName: string) => {
        if (!open || !userEditData) return;
        const group = userEditData.groups[groupName];
        if (!group) return;
        const groupChannels = Array.from(new Set(Object.entries(group).map(([, g]) => g.sources).flat().sort())).filter(cn => userEditData.sources[cn] != undefined);
        const destChannels = Object.keys(userEditData.groups[groupName]).sort();
        return (
            <VStack key={groupName} border="1px solid #ffffff" padding={1}>
                <HStack alignItems="center" justifyContent="space-between"
                        borderBottom="1px solid #ffffff" paddingBottom={1} marginBottom={1}>
                    <Typography color="orangered">{groupName} Group</Typography>
                </HStack>
                <VStack>
                    <HStack justifyContent="space-between">
                        <Typography fontWeight={200}>Channel</Typography>
                        <HStack gap={1}>
                            <HStack>
                                <Typography fontWeight={200}>View</Typography>
                                <Checkbox sx={{p: 0}} checked={groupViewStatus(groupName)[0] === 1}
                                          indeterminate={groupViewStatus(groupName)[0] === -1}
                                          onChange={e => setGroupViewStatus(groupName, e.target.checked)}/>
                            </HStack>
                            <HStack>
                                <Typography fontWeight={200}>Control</Typography>
                                <Checkbox sx={{p: 0}} checked={groupViewStatus(groupName)[1] === 1}
                                          indeterminate={groupViewStatus(groupName)[1] === -1}
                                          onChange={e => setGroupControlStatus(groupName, e.target.checked)}/>
                            </HStack>
                        </HStack>
                    </HStack>
                    {groupChannels.map(channelName => <HStack key={channelName}
                                                              alignItems="center"
                                                              justifyContent="space-between">
                        <Typography>{channelName}</Typography>
                        <HStack marginRight={3.75} gap={5.75}>
                            <Checkbox sx={{p: 0}}
                                      onChange={e => setChannelViewState(channelName, e.target.checked)}
                                      checked={userEditData.sourcePermissions[channelName]?.view ?? false}/>
                            <Checkbox sx={{p: 0}}
                                      onChange={e => setChannelControlState(channelName, e.target.checked)}
                                      checked={userEditData.sourcePermissions[channelName]?.control ?? false}/>
                        </HStack>
                    </HStack>)}

                    {destChannels[0] != "" && <>
                        <HStack justifyContent="space-between" sx={{mt: 2}}>
                            <Typography fontWeight={200}>Destinations</Typography>
                            <HStack>
                                <Typography fontWeight={200}>Go Live</Typography>
                                <Checkbox sx={{p: 0}} checked={groupGoLiveStatus(groupName) === 1}
                                          indeterminate={groupGoLiveStatus(groupName) === -1}
                                          onChange={e => setGroupGoliveStatus(groupName, e.target.checked)}/>
                            </HStack>
                        </HStack>
                        {destChannels.map(dc => <HStack key={dc}
                                                        alignItems="center"
                                                        justifyContent="space-between">
                            <Typography>{userEditData.groups[groupName][dc].destinationName}</Typography>
                            <HStack marginRight={3.75} gap={5.75}>
                                <Checkbox sx={{p: 0}}
                                          onChange={e => setDestinationGoLiveState(dc, e.target.checked)}
                                          checked={userEditData.destinationPermissions[dc] ?? false}/>
                            </HStack>
                        </HStack>)}
                    </>}


                </VStack>
            </VStack>
        );
    }


    return (<>
        <Dialog open={open && readyToOpen} disableEscapeKeyDown maxWidth={false}>
            <form onSubmit={formik.handleSubmit}>
                <DialogTitle>{user === undefined ? "New User" : "Edit user " + user.login + " (" + user.name + ")"}</DialogTitle>
                <DialogContent>
                    <VStack alignItems="flex-end">
                        <HStack gap={3}>
                            <VStack gap={1} sx={{width: 320}}>
                                <TextField
                                    fullWidth
                                    id="login"
                                    name="login"
                                    label="Login"
                                    variant="filled"
                                    type="text"
                                    value={formik.values.login}
                                    onChange={formik.handleChange}
                                    error={formik.touched.login && Boolean(formik.errors.login)}
                                    helperText={formik.touched.login && formik.errors.login}
                                    inputProps={{maxLength: 32}}
                                />
                                <TextField
                                    fullWidth
                                    id="name"
                                    name="name"
                                    label="Name"
                                    variant="filled"
                                    type="text"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    helperText={formik.touched.name && formik.errors.name}
                                    inputProps={{maxLength: 32}}
                                />
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="filled"
                                    type="text"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    inputProps={{maxLength: 32}}
                                />
                                {user !== undefined &&
                                    <Typography sx={{marginTop: 3}}>Leave password field blank to remain
                                        unchanged.</Typography>}
                                <TextField
                                    fullWidth
                                    id="password"
                                    name="password"
                                    label="Password"
                                    variant="filled"
                                    type={showPassword ? "text" : "password"}
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password && formik.errors.password}
                                    inputProps={{maxLength: 32}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                        setShowPassword(!showPassword);
                                                    }}
                                                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                        event.preventDefault();
                                                    }}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <TextField
                                    fullWidth
                                    id="confirm"
                                    name="confirm"
                                    label="Confirm Password"
                                    variant="filled"
                                    type={showConfirm ? "text" : "password"}
                                    value={formik.values.confirm}
                                    onChange={formik.handleChange}
                                    error={formik.touched.confirm && Boolean(formik.errors.confirm)}
                                    helperText={formik.touched.confirm && formik.errors.confirm}
                                    inputProps={{maxLength: 32}}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => {
                                                        setShowConfirm(!showConfirm);
                                                    }}
                                                    onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                        event.preventDefault();
                                                    }}
                                                    edge="end"
                                                >
                                                    {showConfirm ? <VisibilityOff/> : <Visibility/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Typography sx={{width: 320, marginTop: 5}}>Access flags</Typography>
                                <HStack flexWrap="wrap" sx={{width: 320}}>
                                    <FormControlLabel control={<Checkbox
                                        id="staff"
                                        name="staff"
                                        checked={formik.values.staff}
                                        onChange={formik.handleChange}
                                    />} label="Staff Member" sx={{width: 155}}/>
                                    <FormControlLabel control={<Checkbox id="camzoneStaff" name="camzoneStaff"
                                                                         checked={formik.values.camzoneStaff}
                                                                         onChange={formik.handleChange}/>}
                                                      label="Camzone Staff" sx={{width: 155}}/>
                                    <FormControlLabel control={<Checkbox id="editUsers" name="editUsers"
                                                                         checked={formik.values.editUsers}
                                                                         onChange={formik.handleChange}/>}
                                                      label="Can Edit Users" sx={{width: 155}}/>
                                    <FormControlLabel control={<Checkbox id="externalPublish" name="externalPublish"
                                                                         checked={formik.values.externalPublish}
                                                                         onChange={formik.handleChange}/>}
                                                      label="External Publish" sx={{width: 155}}/>
                                    <FormControlLabel control={<Checkbox id="usageStats" name="usageStats"
                                                                         checked={formik.values.usageStats}
                                                                         onChange={formik.handleChange}/>}
                                                      label="Usage Stats" sx={{width: 155}}/>
                                    <FormControlLabel control={<Checkbox id="recordings" name="recordings"
                                                                         checked={formik.values.recordings}
                                                                         onChange={formik.handleChange}/>}
                                                      label="Recordings Access" sx={{width: 155}}/>
                                    <FormControlLabel
                                        control={<Checkbox id="setHome" name="setHome" checked={formik.values.setHome}
                                                           onChange={formik.handleChange}/>} label="Set home position"
                                        sx={{width: 155}}/>
                                    <FormControlLabel control={<Checkbox id="forceJSPull" name="forceJSPull"
                                                                         checked={formik.values.forceJSPull}
                                                                         onChange={formik.handleChange}/>}
                                                      label="Force JS pull" sx={{width: 155}}/>
                                    <FormControlLabel
                                        control={<Checkbox id="enabled" name="enabled" checked={formik.values.enabled}
                                                           onChange={formik.handleChange}/>} label="User Enabled"
                                        sx={{width: 155}}/>
                                </HStack>
                                <Typography sx={{width: 320, marginTop: 5}}>EStop Flags</Typography>
                                <VStack border="1px solid #ffffff" padding={1}>
                                    <HStack alignItems="center" justifyContent="space-between"
                                            borderBottom="1px solid #ffffff" paddingBottom={1} marginBottom={1}>
                                        <Typography color="orangered">Sites</Typography>
                                        <HStack marginRight={1.5} gap={3}>
                                            <Checkbox sx={{p: 0}} checked={estopSitesStatus()[0] === 1}
                                                      indeterminate={estopSitesStatus()[0] === -1}
                                                      onChange={e => setEStopSitesViewStatus(e.target.checked)}/>
                                            <Checkbox sx={{p: 0}} checked={estopSitesStatus()[1] === 1}
                                                      indeterminate={estopSitesStatus()[1] === -1}
                                                      onChange={e => setEStopSitesControlStatus(e.target.checked)}/>
                                        </HStack>
                                    </HStack>
                                    <VStack>
                                        <HStack justifyContent="space-between">
                                            <Typography fontWeight={200}>Site</Typography>
                                            <HStack gap={1}>
                                                <Typography fontWeight={200}>View</Typography>
                                                <Typography fontWeight={200}>Control</Typography>
                                            </HStack>
                                        </HStack>
                                        {userEditData &&
                                            Object.entries(userEditData.estopPermissions).sort((a, b) => ('' + a[1].estopName).localeCompare(b[1].estopName)).map(([, permission]) => <HStack alignItems="center" justifyContent="space-between"
                                                                                                                                                                                              key={permission.estopId}>
                                                <Typography>{permission.estopName}</Typography>
                                                <HStack marginRight={1.5} gap={3}>
                                                    <Checkbox sx={{p: 0}}
                                                              onChange={e => setEstopViewState(permission.estopId, e.target.checked)}
                                                              checked={userEditData.estopPermissions[permission.estopId].view}/>
                                                    <Checkbox sx={{p: 0}}
                                                              onChange={e => setEstopControlState(permission.estopId, e.target.checked)}
                                                              checked={userEditData.estopPermissions[permission.estopId].control}/>

                                                </HStack>
                                            </HStack>)
                                        }
                                    </VStack>
                                </VStack>
                                {userData?.admin && <VStack>
                                    <Typography sx={{width: 320, marginTop: 5}}>Admin Flags</Typography>
                                    <FormControlLabel control={<Checkbox id="admin" name="admin"
                                                                         checked={userEditData ? userEditData.user.admin : false}
                                                                         readOnly/>}
                                                      label="User Is Admin (readonly)" sx={{width: 300}}/>
                                    <FormControlLabel control={<Checkbox id="stealthLogin" name="stealthLogin"
                                                                         checked={formik.values.stealthLogin}
                                                                         onChange={formik.handleChange}/>}
                                                      label="Stealth Login" sx={{width: 300}}/>
                                </VStack>}
                            </VStack>
                            <VStack gap={1} sx={{width: 320}}>
                                {Object.keys(userEditData?.groups ?? {}).sort().map(groupName => camGroupStack(groupName))}
                            </VStack>
                        </HStack>
                    </VStack>
                </DialogContent>
                <DialogActions sx={{p: 2}}>
                    <Button color="primary" variant="outlined" type="submit" onClick={closeDialog}>
                        CANCEL
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                        CONFIRM
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    </>)
}
