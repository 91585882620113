import { Alert, Backdrop, CircularProgress, Snackbar } from "@mui/material";
import * as React from "react";
import {createContext, ReactNode, useContext, useState} from "react";

interface IMessagesContext {
  displayErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  displaySuccessMessage: React.Dispatch<React.SetStateAction<string>>;
  displayInfoMessage: React.Dispatch<React.SetStateAction<string>>;
  setBusy: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MessagesContext = createContext<IMessagesContext>({} as IMessagesContext);
export const useMessagesContext = (): IMessagesContext => {
    const context = useContext(MessagesContext);
    if (context === undefined) throw new Error("useMessagesContext must be within MessagesProvider");
    return context;
};

export const Messages = (props: { children: ReactNode }) => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [infoMessage, setInfoMessage] = useState<string>("");
  const [busy, setBusy] = useState<boolean>(false);
  const duration = 5000;

  return (
    <>
      <MessagesContext.Provider
        value={{
          displayErrorMessage: setErrorMessage,
          displaySuccessMessage: setSuccessMessage,
          displayInfoMessage: setInfoMessage,
          setBusy: setBusy,
        }}
      >
        <Snackbar
          open={errorMessage.length !== 0}
          autoHideDuration={duration}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setErrorMessage("")}
        >
          <Alert severity="error" variant="filled">
            {errorMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={successMessage.length !== 0}
          autoHideDuration={duration}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setSuccessMessage("")}
        >
          <Alert severity="success" variant="filled">
            {successMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={infoMessage.length !== 0}
          autoHideDuration={duration}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => setInfoMessage("")}
        >
          <Alert severity="info" variant="standard">
            {infoMessage}
          </Alert>
        </Snackbar>
        {props.children}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={busy}
        >
          <CircularProgress color="inherit" />
        </Backdrop>{" "}
      </MessagesContext.Provider>
    </>
  );
};
