import {Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Radio, Stack, TextField, Typography} from "@mui/material";
import {useEffect, useRef, useState} from "react";
import {useDataContext} from "../data/Data";
import {TAlert, TChatMessage} from "../data/datatypes";
import dayjs from "dayjs";
import {useWSInterface} from "../data/WSInterface";
import {HStack} from "./SiteTools";

const formatMessageLine = (message: TChatMessage, self: string) => {
    const ts = <Typography sx={{width: 100, minWidth: 100}}>({dayjs(message.created).format("MM-DD HH:mm:ss")})</Typography>;
    const isSelf = message.author === self;
    const isSystem = message.author === "SYSTEM";
    const isAdmin = message.author === "ADMIN";
    let authorcolor = "#000000";
    if (isSelf) authorcolor = "#FF0000";
    else if (isSystem) authorcolor = "#228B22";
    else if (isAdmin) authorcolor = "#ff7300";
    const author = <Typography color={authorcolor} fontWeight="bold">{message.author}: </Typography>;
    const messagetext = <Typography color={isSystem ? "#228B22" : "#000000"}>{message.message}</Typography>;
    const stealthMessage = <Typography color="darkgrey">{message.stealth ? "@" : ""}</Typography>;
    return <HStack key={message.messageId} gap={1}>{ts}{stealthMessage}{author}{messagetext}</HStack>
};

export const Chat = () => {
    const chatViewRef = useRef<HTMLDivElement>(null);
    const [newMessage, setNewMessage] = useState("");
    const {chatMessages, userData} = useDataContext();
    const {sendChatMessage, setAlert} = useWSInterface();
    const [alertDialogOpen, setAlertDialogOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertLevel, setAlertLevel] = useState("error");
    const [alertClearConfirmationOpen, setAlertClearConfirmationOpen] = useState(false);

    useEffect(() => {
        chatViewRef.current?.scroll({top: chatViewRef.current?.scrollHeight, behavior: "smooth"});
    }, [chatMessages]);
    const submitMessage = () => {
        sendChatMessage(newMessage);
        setNewMessage("");
    }

    const alertDialogHandler = () => {
        setAlert({
            message: alertMessage,
            level: alertLevel,
        } as TAlert);
        setAlertDialogOpen(false);
        setAlertClearConfirmationOpen(false);
        setAlertLevel("error");
        setAlertMessage("");
    }


    return (<>
        <Stack direction="column" flexGrow={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{marginBottom: 1}}>
                <Typography>Cam Updates and Communications</Typography>
            </Stack>
            <Stack direction="column" sx={{background: "white", color: "black", fontSize: 14, overflow: "auto", padding: 1.2, height: "100%"}} ref={chatViewRef}>
                {
                    chatMessages.map(message => formatMessageLine(message, (userData ? userData.login : "")))
                }
            </Stack>
            {(userData?.camzoneStaff || userData?.staff) &&
                <Stack direction="row" sx={{marginTop: 1}} gap={1}>
                    <TextField
                        variant="filled"
                        fullWidth sx={{height: 33}}
                        inputProps={{style: {paddingTop: "5px"}}} value={newMessage}
                        onKeyDown={(e) => (e.key === "Enter" ? submitMessage() : "")}
                        onChange={e => setNewMessage(e.currentTarget.value)}
                    ></TextField>
                    <Button variant="contained" onClick={() => submitMessage()}>SEND</Button>
                </Stack>
            }
        </Stack>
        <Dialog open={alertDialogOpen} disableEscapeKeyDown>
            <DialogTitle>Set alert message</DialogTitle>
            <DialogContent>
                <DialogContentText sx={{marginBottom: 3}}>
                    <Typography lineHeight={1.3}>
                        Enter a message and choose a level for the alert.
                    </Typography>
                </DialogContentText>
                <Stack direction="column" gap={3}>
                    <TextField fullWidth variant="outlined" label="Message" onChange={e => setAlertMessage(e.target.value)} inputProps={{maxLength: 200}}></TextField>
                    <Stack direction="column">
                        <Typography>Level</Typography>
                        <Stack direction="column" gap={1}>
                            <Stack direction="row"><Radio checked={alertLevel == "error"} onChange={() => setAlertLevel("error")}/><Alert sx={{width: 250, padding: "1px 10px"}} severity="error">Critical alert message.</Alert></Stack>
                            <Stack direction="row"><Radio checked={alertLevel == "warning"} onChange={() => setAlertLevel("warning")}/><Alert sx={{width: 250, padding: "1px 10px"}} severity="warning">Warning alert message.</Alert></Stack>
                            <Stack direction="row"><Radio checked={alertLevel == "info"} onChange={() => setAlertLevel("info")}/><Alert sx={{width: 250, padding: "1px 10px"}} severity="info">Information alert message.</Alert></Stack>
                            <Stack direction="row"><Radio checked={alertLevel == "success"} onChange={() => setAlertLevel("success")}/><Alert sx={{width: 250, padding: "1px 10px"}} severity="success">Success alert message.</Alert></Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setAlertDialogOpen(false)}>Cancel</Button>
                <Button onClick={alertDialogHandler}>Accept</Button>
            </DialogActions>
        </Dialog>
        <Dialog open={alertClearConfirmationOpen} disableEscapeKeyDown>
            <DialogContent>
                <DialogContentText>This will clear the current alert. Do you want to do this?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setAlertClearConfirmationOpen(false)}>No</Button>
                <Button onClick={alertDialogHandler}>Yes</Button>
            </DialogActions>
        </Dialog>
    </>);
}

