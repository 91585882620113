import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, keyframes, Modal, Paper, PaperProps, Stack, TextField, Typography} from "@mui/material";
import {Lock, LockOpen, OpenInNewOff} from "@mui/icons-material";
import {useDataContext, useUserData} from "../data/Data";
import {useMessagesContext} from "./Messages";
import {useEffect, useRef, useState} from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import {ErrorData} from "../data/APIError";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {slcolor} from "../site/Theme";
import {useWSInterface} from "../data/WSInterface";
import Draggable from 'react-draggable';
import {useSource} from "../data/useSource";

dayjs.extend(utc);

const previewModalStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 640,
    height: 385,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
        >
            <Paper {...props} />
        </Draggable>
    );
}

const blink = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;


export const PopoutChannel = ({channelName}: { channelName: string }) => {
    const userData = useUserData();
    const {groups} = useDataContext();
    const imgRef = useRef<HTMLImageElement>(null);
    const {source, lockSource, inControl, toggleControl, setPoppedOut} = useSource(channelName);
    if (!source) {
        return <></>;
    }
    const {setBusy, displayErrorMessage} = useMessagesContext();
    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [lockDialogOpen, setLockDialogOpen] = useState(false);
    const [lockReason, setLockReason] = useState("");
    const {subscribeChannel, unSubscribeChannel} = useWSInterface();
    const [isLive, setIsLive] = useState(false);

    useEffect(() => {
        let il = false;
        Object.entries(groups).forEach(([, g]) => {
            Object.entries(g).forEach(([, d]) => {
                if (d.cnx == channelName) il = true;
            });
        });
        setIsLive(il);
    }, [groups]);

    useEffect(() => {
        subscribeChannel(source.channelId, source.channelName, source.mjpegName, imgRef);
        return () => unSubscribeChannel(source.channelId, imgRef);
    }, []);
    const lockUnlockChannel = () => {
        if (!lockSource) return;
        setBusy(true);
        lockSource(!source.lockout, lockReason)
            .then(() => {
                setLockReason("");
                setLockDialogOpen(false);
            })
            .catch((err: ErrorData) => {
                displayErrorMessage(err.message);
            })
            .finally(() => setBusy(false));
    };
    const closePopout = () => {
        if (!setPoppedOut) return;
        setPoppedOut(false);
    };

    return (<>
        <Dialog
            open={true}
            onClose={closePopout}
            PaperComponent={PaperComponent}
            hideBackdrop
            disableEnforceFocus
            disableScrollLock
            style={{pointerEvents: 'none'}}
            PaperProps={{style: {pointerEvents: 'auto'}}}
        >
            <DialogContent style={{cursor: 'move'}}>
                <Box>
                    <Stack direction="column" width="fit-content" alignItems="flex-start" gap={0.5}>
                        <Stack direction="row" justifyContent="space-between" width="100%">
                            <Stack direction="row" alignItems="flex-end">
                                <Typography fontSize="12px" lineHeight={1}>{source.displayName}</Typography>
                                {source.lockout ? <Lock sx={{height: 0.7, color: "palegoldenrod"}}/> :
                                    <LockOpen sx={{height: 0.7, color: "palegoldenrod"}}/>}
                                {userData.staff &&
                                    <Button variant="text" onClick={() => setLockDialogOpen(true)} sx={{
                                        padding: 0,
                                        textDecoration: "underline",
                                        color: "white",
                                        fontSize: "12px",
                                        lineHeight: 1,
                                        minWidth: "auto"
                                    }}>{source.lockout ? "Unlock" : "Lock"}</Button>
                                }
                            </Stack>
                            <Stack direction="row" gap={1}>
                                {isLive &&
                                    <Typography sx={{
                                        margin: 0,
                                        lineHeight: "12px",
                                        color: "white",
                                        background: "#e74d15",
                                        padding: "0px 8px",
                                        height: "13px",
                                        fontWeight: 700,
                                        fontSize: "12px",
                                        position: "relative",
                                        top: "9px",
                                    }}>LIVE NOW</Typography>
                                }
                                <OpenInNewOff sx={{position: "relative", bottom: -6, height: 18, cursor: "pointer"}} onClick={closePopout}/>
                            </Stack>
                        </Stack>
                        <Box sx={{position: "relative"}}>
                            <img ref={imgRef} width={352} height={240} alt={channelName}/>
                            {source.controlable && source.control &&
                                <Box sx={{border: inControl?.channelName === channelName ? "2px solid #00ff00" : "none", boxSizing: "border-box", position: "absolute", top: 0, left: 0, width: "100%", height: "calc(100% - 3px)"}}
                                     onClick={() => {
                                         toggleControl ? toggleControl() : ''
                                     }}/>
                            }
                        </Box>
                        <Stack direction="row" justifyContent="space-between" sx={{width: "100%"}}>
                            <Button variant="contained" sx={{paddingTop: 0, paddingBottom: 0}} onClick={() => setPreviewModalOpen(true)}>HD PREVIEW</Button>
                        </Stack>
                    </Stack>
                </Box>
            </DialogContent>
        </Dialog>
        <Dialog open={lockDialogOpen} disableEscapeKeyDown>
            <DialogTitle>{source.lockout ? "UNLOCK" : "LOCK"} {source.displayName}</DialogTitle>
            <DialogContent>
                <Stack direction="column" gap={2}>
                    {source.lockout ? <>
                        <DialogContentText fontWeight="bold">Locked
                            by: {source.whoLockedOutName} on {dayjs(source.timeLockedOut).format("dddd, MMM D, YYYY at h:mm A")}</DialogContentText>
                        <DialogContentText fontWeight="bold">Reason: {source.lockoutMessage}</DialogContentText>
                        <Typography lineHeight={1.3}>
                            Are you sure you want to unlock this camera?
                        </Typography>
                    </> : <>
                        <DialogContentText fontWeight="bold">Reason for locking</DialogContentText>
                        <Typography lineHeight={1.3}>
                            Please enter the reason this camera is being locked. The reason will be visible to other
                            users to inform them why the camera is locked.
                        </Typography>
                        <Box>
                            <TextField
                                autoFocus
                                fullWidth
                                id="reason"
                                name="reason"
                                label="Lock Reason"
                                multiline
                                onChange={(e) => setLockReason(e.target.value)}
                            />
                        </Box>
                    </>}
                </Stack>
            </DialogContent>
            <DialogActions sx={{justifyContent: "space-between", padding: "0px 25px", paddingTop: 1, paddingBottom: 2}}>
                <Button variant="outlined" color="error" onClick={() => {
                    setLockReason("");
                    setLockDialogOpen(false);
                }}>
                    CANCEL
                </Button>
                <Button variant="contained" onClick={lockUnlockChannel}>
                    {source.lockout ? "UNLOCK" : "LOCK"}
                </Button>
            </DialogActions>
        </Dialog>
        <Modal
            open={previewModalOpen}
            onClose={() => setPreviewModalOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={previewModalStyle}>
                <HighlightOffIcon sx={{position: "absolute", top: 0, right: 0, color: slcolor.orange, cursor: "pointer"}} onClick={() => setPreviewModalOpen(false)}/>
                <iframe src={window.location.protocol + "//" + channelName + ".player.camzonecdn.com/v1.2/CamzoneStreamPlayer?iframe=yes&amp;ignorepreroll=yes&amp;channel=" + channelName} width="100%" height="100%"
                        style={{border: 0}}></iframe>
            </Box>
        </Modal>


    </>);
}

