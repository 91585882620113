/* eslint-disable @typescript-eslint/no-explicit-any */
import * as AxiosLogger from "axios-logger";
import axios, {AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import {useDataContext} from "./Data";
import {useMessagesContext} from "../components/Messages";
import {ErrorData, parseError} from "./APIError";
import {useWSInterface} from "./WSInterface";
import {siteConfig} from "../config";
import {TClientActivityLog} from "./datatypes";

axios.defaults.baseURL = siteConfig.baseUrl;
axios.interceptors.request.use((internalrequest: InternalAxiosRequestConfig) => {
    if (internalrequest.url === "/checkSession") {
        return internalrequest;
    }
    const headers = internalrequest.headers;
    const request: AxiosRequestConfig = {
        ...internalrequest,
    };
    const logrequest = AxiosLogger.requestLogger(request);
    internalrequest = {
        ...logrequest,
        ...{headers: headers},
    };
    return internalrequest;
}, AxiosLogger.errorLogger);
axios.interceptors.response.use((internalresponse: AxiosResponse) => {
    if (internalresponse.config.url === "/checkSession") {
        return internalresponse;
    }
    const headers = internalresponse.headers;
    const response: AxiosResponse = {
        ...internalresponse,
    };
    const logresponse = AxiosLogger.responseLogger(response);
    internalresponse = {
        ...logresponse,
        ...{headers: headers},
    };
    return internalresponse;
}, AxiosLogger.errorLogger);

export interface IApiInterface {
    refreshSession: () => void;
    logout: () => void;
    apiGet: (path: string, queryParams?: { [key: string]: string; }) => Promise<any>;
    apiDelete: (path: string, queryParams?: { [key: string]: string; }) => Promise<any>;
    apiPost: (path: string, data?: any, queryParams?: { [key: string]: string; }) => Promise<any>;
    apiPut: (path: string, data?: any, queryParams?: { [key: string]: string; }) => Promise<any>;
    apiPatch: (path: string, data?: any, queryParams?: { [key: string]: string; }) => Promise<any>;
    activityLog: (event: string, data: any) => void;
}

export const useApiInterface = (): IApiInterface => {
    const {stop} = useWSInterface();
    const {setSessionId, processSessionData, clearSession} = useDataContext();
    const {setBusy} = useMessagesContext();

    const logout = () => {
        stop();
        apiPost("/logout")
            .catch(() => {
                return;
            });
        clearSession();
    };
    const apiGet = (path: string, queryParams?: { [key: string]: string; }) => apiRequest("get", path, undefined, queryParams)
    const apiDelete = (path: string, queryParams?: { [key: string]: string; }) => apiRequest("delete", path, undefined, queryParams)
    const apiPost = (path: string, data?: any, queryParams?: { [key: string]: string; }) => apiRequest("post", path, data, queryParams)
    const apiPut = (path: string, data?: any, queryParams?: { [key: string]: string; }) => apiRequest("put", path, data, queryParams)
    const apiPatch = (path: string, data?: any, queryParams?: { [key: string]: string; }) => apiRequest("patch", path, data, queryParams)
    const apiRequest = (method: string, path: string, data?: any, queryParams?: { [key: string]: string; }): Promise<any> => {
        return new Promise((resolve, reject) => {
            axios.request({
                method: method,
                url: path,
                params: queryParams,
                data: data
            })
                .then(data => {
                    if (data.data !== undefined) {
                        resolve(data.data);
                    } else {
                        resolve(null);
                    }
                })
                .catch(errorData => {
                    reject(parseError(errorData));
                });
        })
    }

    const refreshSession = () => {
        setBusy(true);
        if (axios.defaults.headers.common.Authorization === undefined) {
            const localToken = localStorage.getItem("apitoken");
            if (localToken === null) {
                setSessionId("");
                setBusy(false);
                return;
            }
            axios.defaults.headers.common.Authorization = "Bearer " + localToken;
        }
        axios.get("/sessionData")
            .then(data => {
                processSessionData(data.data);
            })
            .catch(() => {
                clearSession();
            })
            .finally(() => setBusy(false));
    }
    const activityLog = (event: string, data: any) => {
        const cal: TClientActivityLog = {
            event: event,
            data: data,
        };
        apiPut("/activityLog", cal)
            .catch((ed: ErrorData) => {
                console.log("error sending activity log", ed)
            });
    }
    return {refreshSession, logout, apiGet, apiDelete, apiPatch, apiPost, apiPut, activityLog}
}
