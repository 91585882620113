import React, {ReactElement} from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import Data from "./data/Data";
import {ThemeProvider} from "@mui/material";
import {theme} from "./site/Theme";
import {Messages} from "./components/Messages";
import { LicenseInfo } from '@mui/x-license-pro';
import {setConfig} from "./site/config";
LicenseInfo.setLicenseKey('bb897bc67a354ab39c8c232945c4bc47Tz03MzczMixFPTE3MjUwMzIwMzQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const app = (
    <ThemeProvider theme={theme}>
        <Data>
            <Messages>
                <App/>
            </Messages>
        </Data>
    </ThemeProvider>
);

fetch("/config.json", {
    method: "get",
    cache: "no-cache",
})
    .then(async (response) => {
        try {
            const data = await response.json();
            console.log("Setting config", data);
            setConfig(data);
            return app;
        } catch (err) {
            console.log("ERR", err);
            return <p style={{ color: "red", textAlign: "center" }}>Error while fetching global config </p>;
        }
    })
    .catch(() => {
        return <p style={{ color: "red", textAlign: "center" }}>Error while fetching global config</p>;
    })
    .then((reactElement: ReactElement) => {
        root.render(reactElement);
    });
