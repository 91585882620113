import {Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import {useDataContext} from "../data/Data";
import {useWSInterface} from "../data/WSInterface";

export const Footer = () => {
    const {sessionId} = useDataContext();
    const {reverted} = useWSInterface();
    return (<>
        <Stack direction="row" justifyContent="space-between" width="100wh" paddingTop={2} paddingBottom={2} paddingLeft={3.7} paddingRight={3.7} color="darkgrey">
            {sessionId && <Typography fontSize={12}>Mode: {reverted ? "JS" : "WS"}</Typography>}
            <Typography fontSize={12}>Copyright © {dayjs().format('YYYY')} Spacelink, Inc.</Typography>
            {sessionId && <Typography fontSize={12}>SID: {sessionId}</Typography>}
        </Stack>
    </>);
}