import {Box, Popover, Stack, Typography} from "@mui/material";
import {useEffect, useState} from "react";
import {useDataContext} from "../data/Data";
import {DataGrid, GridColDef, GridEventListener, useGridApiRef} from "@mui/x-data-grid";
import dayjs from "dayjs";
import {HStack, VStack} from "./SiteTools";
import {config} from "../site/config";

export const UserList = () => {
    const {userList, groups, userGroupList, userData} = useDataContext();
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [selectedUserId, setSelectedUserId] = useState<string>("");
    const rows = Object.entries(userList).map(([, user]) => user);
    const open = Boolean(anchorEl);
    const apiRef = useGridApiRef();

    useEffect(() => {
        console.log("USERGROUPLIST", userGroupList);
    }, [userGroupList]);

    const userGridColumns: GridColDef[] = [
        {
            field: 'login', headerName: 'Username', flex: 1,
            renderCell: (params) => {
                const isSpecial = (userGroupList[params.row.userId] ?? []).includes(config.SPECIAL_GROUP);
                let code = "V";
                let codeColor = "green";
                let title = "Volunteer";
                if (params.row.camzoneStaff) {
                    code = "C";
                    codeColor = "blue";
                    title = "Camzone Staff";
                } else if (params.row.staff) {
                    code = "S";
                    codeColor = "red";
                    title = "Staff";
                }
                let sl = <></>;
                if (userData?.admin && params.row.stealthLogin) {
                    sl = <Typography sx={{fontWeight: "bold", fontSize: 15, color: "darkgrey"}}>@</Typography>
                    title += " (stealth)";
                }
                const icon = <Typography sx={{fontWeight: "bold", fontSize: 15, color: codeColor}}>{code}</Typography>;
                return <Stack direction="row" alignItems="flex-end"><Box title={title} sx={{width: 21}}>{icon}</Box>{sl}<Typography sx={{color: isSpecial ? "red" : "black"}}>{params.row.login}</Typography></Stack>
            }
        },
        {field: 'lastLogin', headerName: 'Logged In', width: 100, valueGetter: ({value}) => value && dayjs(value).format("h:mm a")},
    ];

    const mouseEnterEvent: GridEventListener<'rowMouseEnter'> = (params, event) => {
        setSelectedUserId(params.row.userId);
        setAnchorEl(event.currentTarget);
    }
    const mouseLeaveEvent: GridEventListener<'rowMouseLeave'> = () => {
        setAnchorEl(null);
    }

    useEffect(() => {
        apiRef.current.subscribeEvent(
            'rowMouseEnter',
            mouseEnterEvent,
        );
        apiRef.current.subscribeEvent(
            'rowMouseLeave',
            mouseLeaveEvent,
        );
    }, [apiRef]);


    return (<>
        <Box sx={{width: 250, height: 223, background: "white"}}>
            <DataGrid
                apiRef={apiRef}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'login', sort: 'asc'}],
                    },
                }}
                sx={{
                    border: 0,
                    '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-overlay': {
                        backgroundColor: "transparent"
                    },
                }}
                slots={{
                    noRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            No users logged in
                        </Stack>
                    ),
                }}
                getRowId={(row) => row.userId}
                rows={rows}
                disableColumnMenu
                columns={userGridColumns}
                hideFooter
                rowHeight={30}
                columnHeaderHeight={30}
                style={{color: "black"}}/>
        </Box>
        {userList && userList[selectedUserId] &&
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                disableRestoreFocus
                sx={{
                    pointerEvents: 'none',
                }}

            >
                <VStack sx={{p: 2}}>
                    <HStack justifyContent="flex-start"><Typography sx={{width: 100}}>User name:</Typography><Typography>{userList && selectedUserId != "" && userList[selectedUserId].login}</Typography></HStack>
                    <HStack justifyContent="flex-start"><Typography
                        sx={{width: 100}}>Name:</Typography><Typography>{userList && selectedUserId != "" && userList[selectedUserId].name}</Typography></HStack>
                    <HStack justifyContent="flex-start"><Typography sx={{width: 100}}>Logged
                        in:</Typography><Typography>{userList && selectedUserId != "" && dayjs(userList[selectedUserId].lastLogin).format("ddd, MMM D, YYYY h:mm a")}</Typography></HStack>
                    <HStack justifyContent="flex-start"><Typography
                        sx={{width: 100}}>Group:</Typography><Typography>{userList && selectedUserId != "" && (userList[selectedUserId].camzoneStaff ? "Camzone" : userList[selectedUserId].staff ? "Staff" : "Volunteer")}</Typography></HStack>

                    {userData?.admin && <VStack>
                        <HStack justifyContent="flex-start"><Typography sx={{width: 100}}>Admin?</Typography>
                            <Typography>{userList && selectedUserId != "" && userList[selectedUserId].admin ? "Yes" : "No"}</Typography>
                        </HStack>
                        <HStack justifyContent="flex-start"><Typography sx={{width: 100}}>Stealth?</Typography>
                            <Typography>{userList && selectedUserId != "" && userList[selectedUserId].stealthLogin ? "Yes" : "No"}</Typography>
                        </HStack>
                    </VStack>}

                    <HStack justifyContent="flex-start">
                        <Typography sx={{width: 100}}>Cam Access:</Typography>
                        <VStack>{(userGroupList[selectedUserId] ?? []).map((name) => <Typography key={name}>{name}</Typography>)}</VStack>
                    </HStack>

                </VStack>
            </Popover>
        }
    </>);
}
