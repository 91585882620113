import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import {useDataContext} from "../data/Data";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useWSInterface} from "../data/WSInterface";
import {DataGridPro} from "@mui/x-data-grid-pro";
import {Edit, PlayArrow, Stop} from "@mui/icons-material";
import {TExternalTarget} from "../data/datatypes";
import {useApiInterface} from "../data/useApiInterface";
import {ErrorData} from "../data/APIError";
import {useMessagesContext} from "./Messages";


export const ExternalPublish = () => {
    const {externalTargets} = useDataContext();
    const {enableExternalPublishData} = useWSInterface();
    const [selectedTargetId, setSelectedTargetId] = useState("");
    const [editTargetOpen, setEditTargetOpen] = useState(false);
    const { apiPost } = useApiInterface();
    const { setBusy,displayErrorMessage } = useMessagesContext();

    useEffect(() => {
        enableExternalPublishData(true);
        return () => enableExternalPublishData(false);
    }, []);

    const startStream = (targetId: string)=>{
        setBusy(true);
        apiPost("/externalTarget/"+targetId+"/startPublish")
            .catch((err)=>{
                 displayErrorMessage(err.message);
            })
            .finally(()=>setBusy(false));
    }
    const stopStream = (targetId: string)=>{
        setBusy(true);
        apiPost("/externalTarget/"+targetId+"/stopPublish")
            .catch((e)=>displayErrorMessage(e.message))
            .finally(()=>setBusy(false));
    }

    const externalTargetsGridColumns: GridColDef[] = [
        {field: 'source_channel', headerName: 'Camzone Channel', width: 150},
        {field: 'destination_name', headerName: 'Destination', width: 200},
        {
            field: 'current_state', headerName: 'Status', flex: 1,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                if (params.id === undefined) return "";
                const statusDetail = params.api.getRow(params.id).current_state_detail;
                return statusDetail.length === 0 ? params.value : params.value + ": " + statusDetail;
            },
        },
        {
            field: 'auto_reconnect',
            headerName: 'Auto Reconnect',
            width: 120,
            valueFormatter: (params: GridValueFormatterParams<string>) => {
                return params.value === "true" ? "Yes" : "No";
            }
        },
        {
            field: 'actions', headerName: 'Actions', width: 100, sortable: false,
            renderCell: (params) => {
                let actions: JSX.Element;
                if (params.row.current_state === "published") {
                    actions = <Button onClick={e => {
                        e.currentTarget.blur();
                        stopStream(params.id as string);
                    }} sx={{p: 0, minWidth: 20}} title="Stop live stream"><Stop/></Button>;

                } else {
                    actions = <><Button onClick={e => {
                        e.currentTarget.blur();
                        setSelectedTargetId(params.id.toString());
                        setEditTargetOpen(true);
                    }} sx={{p: 0, minWidth: 20}} title="Edit target"><Edit/></Button><Button onClick={e => {
                        e.currentTarget.blur();
                        startStream(params.id as string);
                    }} sx={{p: 0, minWidth: 20}} title="Start live stream"><PlayArrow/></Button></>;
                }
                return <Stack direction="row" gap={2}>{actions}</Stack>;
            }
        },
    ];

    return (<>
        <Box sx={{width: "auto", padding: 2}}>
            <DataGridPro
                initialState={{
                    sorting: {
                        sortModel: [{field: 'source_channel', sort: 'asc'}, {field: 'destination_name', sort: 'asc'}],
                    },
                }}
                sx={{
                    '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                }}
                disableColumnMenu autoHeight hideFooter disableRowSelectionOnClick
                rows={Object.entries(externalTargets).map(([, target]) => target)}
                columns={externalTargetsGridColumns}/>
        </Box>
        <EditTargetDialog selectedTargetId={selectedTargetId} open={editTargetOpen} setOpen={setEditTargetOpen}/>
    </>)
}

export const EditTargetDialog = ({open, setOpen, selectedTargetId}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    selectedTargetId: string;
}) => {
    const {externalTargets} = useDataContext();
    const [publishUrl, setPublishUrl] = useState("");
    const [autoReconnect, setAutoReconnect] = useState("false");
    const {apiPatch} = useApiInterface();
    const [target, setTarget] = useState<TExternalTarget>();
    const {setBusy, displayErrorMessage} = useMessagesContext();
    const [errorMessage, setErrorMessage] = useState("");
    const closeDialog = () => {
        setPublishUrl("");
        setAutoReconnect("false");
        setTarget(undefined);
        setOpen(false);
    }
    useEffect(() => {
        const t = externalTargets.filter(v => v.id == selectedTargetId);
        if (t.length === 0) {
            setPublishUrl("");
            setAutoReconnect("false");
            setTarget(undefined);
        } else {
            setPublishUrl(t[0].publish_url);
            setAutoReconnect(t[0].auto_reconnect)
            setTarget(t[0]);
        }
        setErrorMessage("");
    }, [open]);

    const saveTarget = () => {
        const data: { publish_url: string; auto_reconnect: string; } = {
            publish_url: publishUrl,
            auto_reconnect: autoReconnect,
        };
        setBusy(true);
        setErrorMessage("");
        apiPatch("/externalTarget/" + selectedTargetId, data)
            .then(() => {
                closeDialog();
            })
            .catch((ed: ErrorData) => {
                if(ed.code==="E_FIELDVALIDATION") {
                    setErrorMessage("Invalid URL");
                }
                displayErrorMessage(ed.message);
            })
            .finally(() => setBusy(false));
    }


    return (
        <Dialog open={open} disableEscapeKeyDown>
            <DialogTitle>Target Configuration</DialogTitle>
            <DialogContent sx={{pb: 0}}>
                <Stack direction="column">
                    <Typography fontWeight="bold">Channel: {target && target.source_channel}</Typography>
                    <Typography fontWeight="bold">Destination: {target && target.destination_name}</Typography>
                </Stack>
                <Stack direction="column" justifyContent="space-evenly" height={370}>
                    <Stack direction="column" gap={1}>
                        <Typography fontWeight="bold">Connection String</Typography>
                        <Typography>The connection string indicates how to connect to the external service. This value
                            will be provided by Camzone or the 3rd party service and should be entered here exactly as
                            provided. Connection strings usually start with rtmp://</Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="publish_url"
                            label="Connection String"
                            multiline
                            rows={3}
                            type="text"
                            fullWidth
                            variant="filled"
                            value={publishUrl}
                            onChange={e => setPublishUrl(e.currentTarget.value)}
                            error={errorMessage.length>0}
                            helperText={errorMessage}
                        />
                    </Stack>
                    <Stack direction="column" gap={1}>
                        <Typography fontWeight="bold">Auto reconnect</Typography>
                        <Typography>If enabled, the system will attempt to re-connect if the connection is broken. When
                            not set, the broadcast will terminate upon disconnection.</Typography>
                        <FormControl fullWidth>
                            <Select
                                value={autoReconnect}
                                onChange={e => setAutoReconnect(e.target.value)}
                                variant="filled"
                                sx={{
                                    paddingTop: 0,
                                    "& .MuiSelect-select": {
                                        paddingTop: 1
                                    }
                                }}
                            >
                                <MenuItem value={"true"}>Yes - re-connect upon disconnection</MenuItem>
                                <MenuItem value={"false"}>No - stop broadcast upon disconnection</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog}>Cancel</Button>
                <Button onClick={saveTarget}>Apply</Button>
            </DialogActions>
        </Dialog>
    );
}