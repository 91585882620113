export interface ErrorData {
    description: string;
    code: string;
    message: string;
    status: number;
    statusText: string;
    details?: {
// eslint-disable-next-line @typescript-eslint/no-explicit-any
        [field: string]: any;
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const parseError = (error: any): ErrorData => {
    const ed: ErrorData = {
        description: "",
        code: "",
        message: "",
        status: 0,
        statusText: "",
    };

    if (error.response === undefined) {
        return {
            ...ed,
            ...{
                error: "UNKNOWNERROR",
                message: error.message,
            },
        };
    }

    ed.status = error.response.status;
    ed.statusText = error.response.statusText;
    const data = error.response.data;
    if (typeof data === "string") {
        ed.message = data;
    } else {
        ed.description = data.description;
        ed.code = data.code;
        ed.message = data.message;
        if (data.details !== undefined) {
            ed.details = data.details;
        }
    }
    if (ed.message == "" && ed.description !== "") {
        ed.message = ed.description;
    }
    return ed;
};
