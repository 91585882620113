import {Box, Button, Modal, Stack, useMediaQuery} from "@mui/material";
import {Dispatch, memo, SetStateAction, useEffect, useRef, useState} from "react";
import {useMessagesContext} from "./Messages";
import {Event, TRecording} from "../data/datatypes";
import {useApiInterface} from "../data/useApiInterface";
import {GridColDef, GridToolbar, GridValueFormatterParams} from "@mui/x-data-grid";
import {DataGridPro} from "@mui/x-data-grid-pro";
import dayjs from "dayjs";
import {siteConfig} from "../config";
import Player from "video.js/dist/types/player";
import videojs from "video.js";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {slcolor} from "../site/Theme";
import VideoJS from "./VideoJS";

export const Recordings = () => {
    const {apiGet, activityLog} = useApiInterface();
    const {setBusy, displayErrorMessage} = useMessagesContext();
    const [recordings, setRecordings] = useState<TRecording[]>([]);
    useEffect(() => {
        setBusy(true);
        apiGet("/recordings")
            .then(data => {
                setRecordings(data);
            })
            .catch(e => displayErrorMessage(e.message()))
            .finally(() => setBusy(false));
    }, []);

    const recordingGridColumns: GridColDef[] = [
        {field: 'recordingId', headerName: 'Recording ID', width: 150},
        {field: 'channelname', headerName: 'Channel', minWidth: 200, flex: 1},
        {
            field: 'date',
            headerName: 'Start Date/Time',
            type: "dateTime",
            width: 200,
            valueGetter: ({value}) => value && dayjs(value).toDate()
        },
        {
            field: 'size',
            headerName: 'Size',
            width: 100,
            valueFormatter: (params: GridValueFormatterParams<number>) => {
                const formatBytes = (bytes: number, decimals = 2): string => {
                    if (!+bytes) return '0 Bytes'
                    const k = 1000
                    const dm = decimals < 0 ? 0 : decimals
                    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
                    const i = Math.floor(Math.log(bytes) / Math.log(k))
                    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
                }
                if (params.value == null) {
                    return '';
                }
                return formatBytes(params.value, 1);
            }
        },
        {
            field: 'actions',
            headerName: 'Action',
            width: 150,
            renderCell: params => {
                return <Stack direction="row" gap={0}>
                    {params.row.type == "archive" &&
                        <Button variant="text"
                                sx={{minWidth: 0, padding: "0px 4px"}}
                                onClick={() => {
                                    setPreviewSrc(window.location.protocol + "//" + params.row.address + "/" + params.row.channelname + "/" + params.row.file);
                                    setPreviewID(params.id.toString());
                                    setPreviewModalOpen(true);
                                    activityLog(Event.RECORDING_VIEWED, params.id);
                                }}>[VIEW]</Button>
                    }
                    <Button variant="text"
                            sx={{minWidth: 0, padding: "0px 4px"}}
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = siteConfig.baseUrl + '/recordings/' + params.id;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                activityLog(Event.RECORDING_DOWNLOADED, params.id);
                            }}>[DOWNLOAD]</Button>
                </Stack>
            },
        },
    ];

    const [previewModalOpen, setPreviewModalOpen] = useState(false);
    const [previewSrc, setPreviewSrc] = useState("");
    const [previewID, setPreviewID] = useState("");


    return (<>
        {recordings.length != 0 &&
            <Box sx={{p: 3}}>
                <DataGridPro
                    initialState={{
                        sorting: {
                            sortModel: [{field: 'channelname', sort: 'asc'}, {field: 'date', sort: 'desc'}],
                        },
                    }}
                    sx={{
                        '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                            outline: 'none',
                        },
                    }}
                    getRowId={(row) => row.recordingId}
                    density="compact"
                    disableColumnMenu autoHeight hideFooter disableRowSelectionOnClick
                    disableColumnFilter disableColumnSelector disableDensitySelector
                    slots={{toolbar: GridToolbar}}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: true,
                            printOptions: {disableToolbarButton: true},
                            csvOptions: {disableToolbarButton: true},
                        },
                    }}
                    rows={recordings}
                    columns={recordingGridColumns}/>
            </Box>
        }
        <ViewModal previewModalOpen={previewModalOpen}
                   setPreviewModalOpen={setPreviewModalOpen}
                   previewSrc={previewSrc}
                   previewID={previewID}
        />
    </>)
}

type VideoJsOptionSource = {
    src: string,
    type: string,
}

type VideoJsOptions = {
    autoplay: boolean,
    controls: boolean,
    responsive: boolean,
    fluid: boolean,
    inactivityTimeout: number,
    sources: VideoJsOptionSource[],
}

const ViewModal = memo(({previewModalOpen, setPreviewModalOpen, previewSrc, previewID}: { previewModalOpen: boolean, setPreviewModalOpen: Dispatch<SetStateAction<boolean>>, previewSrc: string, previewID: string }) => {
    const {activityLog} = useApiInterface();
    const playerRef = useRef<Player | null>(null);
    const fullPreview = useMediaQuery('(min-width: 1360px)');
    const [videoJsOptions, setVideoJsOptions] = useState<VideoJsOptions>({
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        inactivityTimeout: 0,
        sources: []
    });
    useEffect(() => {
        console.log("previewModalOpen", previewModalOpen);
    }, [previewModalOpen]);
    useEffect(() => {
        console.log("videoJsOptions", videoJsOptions);
    }, [videoJsOptions]);
    useEffect(() => {
        console.log("previewSrc", previewSrc);
        setVideoJsOptions(v => {
            v.sources = [{
                src: previewSrc,
                type: 'video/mp4',
            }];
            return v;
        })
    }, [previewSrc]);
    useEffect(() => {
        console.log("previewID", previewID);
    }, [previewID]);
    const handleModalClose = (event: object, reason: string) => {
        if (reason && reason == "backdropClick")
            return;
        setPreviewModalOpen(false);
    }
    const handlePlayerReady = (player: Player | null) => {
        if (!player) return;
        playerRef.current = player;

        // You can handle player events here, for example:
        player.on('waiting', () => {
            console.log("player is waiting");
            videojs.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log("player will dispose");
            videojs.log('player will dispose');
        });
    };
    return (<>
        <Modal
            open={previewModalOpen}
            onClose={handleModalClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown
        >
            <Box sx={{
                position: 'absolute' as const,
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: fullPreview ? 1280 : 640,
                height: (fullPreview ? 1280 : 640) * 0.5625 + 35,
                bgcolor: 'background.paper',
                border: '2px solid #000',
                boxShadow: 24,
                p: 4,
            }}>
                <HighlightOffIcon sx={{position: "absolute", top: 0, right: 0, color: slcolor.orange, cursor: "pointer"}} onClick={() => setPreviewModalOpen(false)}/>
                <Stack direction="column" gap={1}>
                    <Box sx={{
                        width: (fullPreview ? 1280 : 640),
                        height: ((fullPreview ? 1280 : 640) * 0.5625 + 35) - 32,
                    }}>
                        <VideoJS options={videoJsOptions}
                                 onReady={handlePlayerReady}
                        />
                    </Box>
                    <Button variant="contained"
                            onClick={() => {
                                const link = document.createElement('a');
                                link.href = siteConfig.baseUrl + '/recordings/' + previewID;
                                document.body.appendChild(link);
                                link.click();
                                document.body.removeChild(link);
                                activityLog(Event.RECORDING_DOWNLOADED, parseInt(previewID));
                            }}>DOWNLOAD RECORDING</Button>

                </Stack>
            </Box>
        </Modal>
    </>)
});
ViewModal.displayName = 'ViewModal';
