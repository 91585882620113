import {Box, Button, IconButton, InputAdornment, Stack, TextField, Typography} from "@mui/material";
import * as yup from "yup";
import React, {Dispatch, SetStateAction, useState} from "react";
import YupPassword from "yup-password";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useFormik} from "formik";
import {useApiInterface} from "../data/useApiInterface";
import {useMessagesContext} from "./Messages";
import {ErrorData} from "../data/APIError";

YupPassword(yup);

const requiredField = () => yup.string().required("Password is required");
const passwordField = () =>
    requiredField()
        .min(8, "password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special")
        .minLowercase(1, "password must contain at least 1 lower case letter")
        .minUppercase(1, "password must contain at least 1 upper case letter")
        .minNumbers(1, "password must contain at least 1 number")
        .minSymbols(1, "password must contain at least 1 special character");
const validationSchema = yup.object({
    password: passwordField(),
    code: yup.string().length(6, "Code should be 6 numbers").required("Verification code required"),
    confirm: yup
        .string()
        .oneOf([yup.ref("password"), undefined], "Passwords must match")
        .required("Confirm Password is required"),
});

export const ForgotPassword = ({setSuccessMessage, setForgotPassword}: {
    setSuccessMessage: Dispatch<SetStateAction<string>>,
    setForgotPassword: Dispatch<SetStateAction<boolean>>
}) => {
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [email, setEmail] = useState("");
    const [viewConfirm, setViewConfirm] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const {apiPost} = useApiInterface();
    const {setBusy, displayErrorMessage} = useMessagesContext();

    const formik = useFormik({
        initialValues: {
            password: "",
            confirm: "",
            code: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setBusy(true);
            setErrorMessage("");
            apiPost("/auth/resetpassword", {email: email, code: values.code, newpassword: values.password})
                .then(() => {
                    setSuccessMessage("Your password has been changed");
                    setForgotPassword(false);
                })
                .catch(err => {
                    if (err.code === "E_FIELDVALIDATION") {
                        setErrorMessage("Invalid password.  Input another password.");
                    } else if (err.code === "E_BADREQUEST") {
                        setErrorMessage("Invalid verification code");
                    } else {
                        setErrorMessage(err.description + " (" + err.message + ")");
                    }
                })
                .finally(() => setBusy(false))
        },
    });

    const submitEmail = () => {
        setBusy(true);
        apiPost("/auth/resetpassword", {email: email})
            .catch((err: ErrorData) => {
                if (err.code === 'E_RESETEMAILSENT') {
                    setViewConfirm(true);
                } else {
                    displayErrorMessage(err.message);
                }
            })
            .finally(() => setBusy(false));
    };
    return <>
        {!viewConfirm ?
            <>
                <Typography align="center">Forgot your username or password? No problem. Enter the email address used
                    when you first registered and we will send your instructions.</Typography>
                <TextField
                    fullWidth
                    autoFocus
                    variant="filled"
                    label="Email"
                    onChange={e => setEmail(e.target.value)}
                    onKeyDown={(e) => (e.key === "Enter" ? submitEmail() : "")}
                    value={email}
                />
                <Typography align="center">If you are still having trouble or do not receive the email, please contact
                    support or the volunteer coordinator.</Typography>
                <Stack direction="row" justifyContent="space-between" width="100%">
                    <Button color="primary" variant="outlined" onClick={() => setForgotPassword(false)}>
                        CANCEL
                    </Button>
                    <Button color="primary" variant="contained" onClick={submitEmail}>
                        SUBMIT
                    </Button>
                </Stack>
            </>
            :
            <form onSubmit={formik.handleSubmit}>
                <Stack direction="column" gap={3} width={350} alignItems="center" margin="auto">
                    {errorMessage &&
                        <Box sx={{background: "#ff00006b", padding: 2}}>
                            <Typography sx={{color: "white", fontSize: 16}}>{errorMessage}</Typography>
                        </Box>
                    }
                    <Typography align="center">Please type in your verification code and new password</Typography>
                    <TextField
                        fullWidth
                        id="code"
                        name="code"
                        label="Verification Code"
                        variant="filled"
                        type="text"
                        autoFocus
                        value={formik.values.code}
                        onChange={formik.handleChange}
                        error={formik.touched.code && Boolean(formik.errors.code)}
                        helperText={formik.touched.code && formik.errors.code}
                        inputProps={{maxLength: 6}}
                        onKeyDown={() => {setErrorMessage("");}}
                    />
                    <TextField
                        fullWidth
                        id="password"
                        name="password"
                        label="Password"
                        variant="filled"
                        type={showPassword ? "text" : "password"}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        onKeyDown={() => {setErrorMessage("");}}
                        inputProps={{maxLength: 32}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            setShowPassword(!showPassword);
                                        }}
                                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        fullWidth
                        id="confirm"
                        name="confirm"
                        label="Confirm Password"
                        variant="filled"
                        type={showConfirm ? "text" : "password"}
                        value={formik.values.confirm}
                        onChange={formik.handleChange}
                        error={formik.touched.confirm && Boolean(formik.errors.confirm)}
                        helperText={formik.touched.confirm && formik.errors.confirm}
                        inputProps={{maxLength: 32}}
                        onKeyDown={() => {setErrorMessage("");}}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            setShowConfirm(!showConfirm);
                                        }}
                                        onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.preventDefault();
                                        }}
                                        edge="end"
                                    >
                                        {showConfirm ? <VisibilityOff/> : <Visibility/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Stack direction="row" justifyContent="space-between" width="100%">
                        <Button color="primary" variant="outlined" onClick={() => setForgotPassword(false)}>
                            CANCEL
                        </Button>
                        <Button color="primary" variant="contained" type="submit" >
                            CONFIRM
                        </Button>
                    </Stack>
                </Stack>
            </form>
        }
    </>;
}