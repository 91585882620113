import {Alert, Box, Button, IconButton, InputAdornment, Link, Stack, TextField, Typography} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import * as yup from "yup";
import {CamzoneLogo} from "./CamzoneLogo";
import {useFormik} from "formik";
import React, {useState} from "react";
import {useApiInterface} from "../data/useApiInterface";
import {useMessagesContext} from "./Messages";
import {useDataContext} from "../data/Data";
import {ErrorData} from "../data/APIError";
import {ForgotPassword} from "./ForgotPassword";
import {slcolor} from "../site/Theme";
import {config} from "../site/config";

const validationSchema = yup.object({
    username: yup.string().max(100, "Username can only be 100 characters max").required("Username is required"),
    password: yup.string().required("Password is required"),
});

export const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const {apiPost} = useApiInterface();
    const {setBusy} = useMessagesContext();
    const {
        processSessionData,
        clearSession,
        loginErrorMessage,
        loginInfoMessage,
        loginSuccessMessage,
        setLoginErrorMessage,
        setLoginSuccessMessage,
        setLoginInfoMessage
    } = useDataContext();
    const [forgotPassword, setForgotPassword] = useState(false);
    const formik = useFormik({
        initialValues: {
            username: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setBusy(true);
            setLoginSuccessMessage("");
            setLoginErrorMessage("");
            setLoginInfoMessage("");
            apiPost("/auth/login", values)
                .then(sessionData => processSessionData(sessionData))
                .catch((err: ErrorData) => {
                    clearSession();
                    if (err.description.length < 4 || err.message.includes("Bad Gateway")) {
                        setLoginErrorMessage("There was a problem attempting to login.  Please try again in a little bit.");
                    } else {
                        setLoginErrorMessage(err.description + " (" + err.message + ")");
                    }
                })
                .finally(() => setBusy(false));
        },
    });
    return <>
        <Stack direction="column" gap={3} width={700} alignItems="center" margin="auto" marginTop={3}>
            {config.ENABLE_LEGACY_SITE_LINK &&
                <Alert severity="info" sx={{fontSize: 16}}>Welcome to the updated camera control portal. If you experience
                    difficulties with this system please contact support to let us know. The previous experience will remain
                    available for approximately one week and may be accessed by clicking <Link
                        href="http://zssd-legacy.admin.camzone.com">here</Link>.
                </Alert>
            }
            <Stack direction="column" gap={3} width={350} alignItems="center" margin="auto" marginTop={3}>
                <CamzoneLogo style={{marginBottom: 35, height: 85}}/>
                {!forgotPassword && <>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack direction="column" gap={3} width={350} alignItems="center" margin="auto">
                            {loginSuccessMessage &&
                                <Box sx={{background: "rgba(58,197,4,0.42)", padding: 2}}>
                                    <Typography sx={{color: "white", fontSize: 16}}>{loginSuccessMessage}</Typography>
                                </Box>
                            }
                            {loginErrorMessage &&
                                <Box sx={{background: "#ff00006b", padding: 2}}>
                                    <Typography sx={{color: "white", fontSize: 16}}>{loginErrorMessage}</Typography>
                                </Box>
                            }
                            {loginInfoMessage &&
                                <Box sx={{background: slcolor.orange, padding: 2}}>
                                    <Typography sx={{color: "white", fontSize: 16}}>{loginInfoMessage}</Typography>
                                </Box>
                            }
                            <TextField
                                fullWidth
                                autoFocus
                                variant="filled"
                                id="username"
                                name="username"
                                label="Username"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                            />
                            <TextField
                                fullWidth
                                id="password"
                                name="password"
                                label="Password"
                                variant="filled"
                                type={showPassword ? "text" : "password"}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => {
                                                    setShowPassword(!showPassword);
                                                }}
                                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                    event.preventDefault();
                                                }}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <Stack direction="row" justifyContent="space-between" width="100%">
                                <Button variant="text"
                                        sx={{
                                            color: "white",
                                            paddingLeft: 0,
                                            paddingRight: 0,
                                            textTransform: "none",
                                            fontSize: 13
                                        }}
                                        onClick={() => {
                                            setLoginSuccessMessage("");
                                            setLoginErrorMessage("");
                                            setLoginInfoMessage("");
                                            formik.resetForm();
                                            setForgotPassword(true);
                                        }}>Forgot login or password?</Button>
                                <Button color="primary" variant="contained" type="submit">
                                    LOG IN
                                </Button>
                            </Stack>
                        </Stack>
                    </form>
                </>}
                {forgotPassword &&
                    <ForgotPassword setForgotPassword={setForgotPassword} setSuccessMessage={setLoginSuccessMessage}/>}
            </Stack>
        </Stack>
    </>;
}
