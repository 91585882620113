import {Dispatch, SetStateAction} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack} from "@mui/material";
import {ErrorOutline} from "@mui/icons-material";
import {TUser} from "../data/datatypes";
import {useApiInterface} from "../data/useApiInterface";
import {ErrorData} from "../data/APIError";
import {useMessagesContext} from "./Messages";

export const UserDeleteConfirmDialog = ({
                                            open,
                                            setOpen,
                                            user,
                                            userList,
                                            setUserList,
                                        }: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    user: TUser | undefined;
    userList: TUser[];
    setUserList: Dispatch<SetStateAction<TUser[]>>;
}) => {
    const {apiDelete} = useApiInterface();
    const {setBusy, displayErrorMessage} = useMessagesContext();
    const handleClose = () => {
        setOpen(false);
    };
    const handleOk = () => {
        if (!user) return;
        setBusy(true);
        apiDelete("/userManager/user/" + user.userId)
            .then(() => {
                const ul = userList.filter(u => u.userId != user.userId);
                setUserList(ul);
                setOpen(false);
            })
            .catch((ed: ErrorData) => displayErrorMessage(ed.message))
            .finally(() => setBusy(false));
    };

    return (
        <div>
            <Dialog open={open} disableEscapeKeyDown>
                <DialogTitle>
                    <Stack direction="row" alignItems="center" gap={1} color="red">
                        <ErrorOutline/>
                        Delete User
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" gap={2}>
                        <DialogContentText>Deleting a user is permanent.</DialogContentText>
                        <DialogContentText>Are you sure you want to
                            delete &quot;{user && user.login}&quot;?</DialogContentText>
                    </Stack>
                </DialogContent>
                <DialogActions
                    sx={{justifyContent: "space-between", padding: "0px 25px", paddingTop: 1, paddingBottom: 2}}>
                    <Button onClick={handleClose} variant="outlined">
                        CANCEL
                    </Button>
                    <Button onClick={handleOk} variant="outlined" color="error">
                        DELETE
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
