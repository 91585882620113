export interface ISessionData {
    sessionId: string;
    token: string;
    expire: string;
    idleTimeout: number;
    timeoutWarning: number;
    idleSeconds: number;
    checkedInSeconds: number;
    lastCheckIn: string;
    lastSeen: string;
    ipAddress: string;
    isAdminLogin: boolean;
    createdAt: string;
    user: TUser;
    sources: ISourceList;
    groups: IGroupList;
    destinationPermissions: IDestinationPermissions;
}

export interface IUserList {
    [userId: string]: TUser
}

export type TUser = {
    userId: number;
    login: string;
    password?: string;
    email: string;
    name: string;
    enabled: boolean;
    staff: boolean;
    camzoneStaff: boolean;
    editUsers: boolean;
    recordings: boolean;
    externalPublish: boolean;
    usageStats: boolean;
    lastLogin: string | null;
    setHome: boolean;
    mediaManager: boolean;
    channelSettings: boolean;
    forceJSPull: boolean;
    admin: boolean;
    stealthLogin: boolean;
}

export interface IUserGroupList {
    [userId: string]: string[]
}

export interface ISourceList {
    [channelName: string]: TSource
}

export type TSource = {
    channelName: string;
    mjpegName: string;
    controlable: boolean;
    lockout: boolean;
    sortOrder: number;
    enable: boolean;
    displayName: string;
    key: string;
    timeLockedOut: string;
    whoLockedOut: number;
    lockoutMessage: string;
    channelId: number;
    userId: number;
    eStopId: string;
    eStopButtonAvailable: boolean;
    eStop: TEStop;
    whoLockedOutName: string;
    control: boolean;
}

export type TGroup = {
    [destinationChannel: string]: TDestination;
}

export interface IGroupList {
    [groupName: string]: TGroup
}

export type TDestination = {
    groupName: string;
    sources: string[];
    destinationName: string;
    destinationChannel: string;
    cnx: string;
    eStopId: string;
    eStopButtonAvailable: boolean;
    eStop: TEStop;
}

export interface IDestinationPermissions {
    [destinationChannel: string]: boolean
}

export type TEStop = {
    status: string;
    statusLabel: string;
    description: string;
    when: string;
    enabled: boolean;
    buttonLabel: string;
    virtualButtonId: string;
}

export type TChatMessage = {
    messageId: number;
    author: string;
    created: string;
    message: string;
    stealth: boolean;
}

export type TExternalTarget = {
    id: string;
    source_channel: string;
    publish_url: string;
    publish_state: string;
    destination_name: string;
    current_state: string;
    current_state_detail: string;
    auto_reconnect: string;
}

export type TRecording = {
    recordingId: number
    date: string
    location: string
    file: string
    size: number
    deleted: boolean
    owner: string
    channelname: string
    address: string
    appname: string
    type: string
}

export type TPermission = {
    userid: number
    channel: string
    view: boolean
    control: boolean
}

export type TEStopPermission = {
    userid: number
    estopId: string
    estopName: string
    view: boolean
    control: boolean
}

export interface IEStopPermissions {
    [estopId: string]: TEStopPermission
}

export type TAlert = {
    message: string
    level: string
}

export interface IUserEditData {
    user: TUser
    sourcePermissions: { [channel: string]: TPermission }
    sources: ISourceList
    groups: IGroupList
    destinationPermissions: IDestinationPermissions
    estopPermissions: IEStopPermissions
}

export type TClientActivityLog = {
    event: string
    data: any
}

export const Event = Object.freeze({
    RECORDING_DOWNLOADED: "recording_downloaded",
    RECORDING_VIEWED: "recording_viewed",
})
