import {ReactNode, useEffect, useState} from "react";
import {SessionWarning} from "../components/SessionWarning";
import dayjs from "dayjs";
import axios, {InternalAxiosRequestConfig} from "axios";
import {useDataContext} from "./Data";
import {useApiInterface} from "./useApiInterface";

export const Session = ({children}: { children: ReactNode }) => {
    const {idleTimeout, timeoutWarning, clearSession, sessionId} = useDataContext();
    const [lastActivity, setLastActivity] = useState<number>(0);
    const [showWarning, setShowWarning] = useState(false);
    const {apiGet, logout} = useApiInterface();
    useEffect(() => {
        const interval = setInterval(() => {
            if (!lastActivity) return;
            const secssince = dayjs().unix() - lastActivity;
            if (secssince >= idleTimeout) {
                return clearSession();
            }
            const secsToLog = idleTimeout - secssince;
            const warning = secsToLog < timeoutWarning;
            setShowWarning(warning);
        }, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [lastActivity, idleTimeout, null]);

    useEffect(() => {
        const interceptor = axios.interceptors.request.use((internalrequest: InternalAxiosRequestConfig) => {
            if (internalrequest.url !== "/checkSession") {
                resetActivityTimer();
            }
            return internalrequest;
        });
        return () => axios.interceptors.response.eject(interceptor);
    }, []);

    useEffect(() => {
        let lastSuccessSession = dayjs().unix();
        const interval = setInterval(() => {
            if (!sessionId) return;
            apiGet("/checkSession")
                .then(() => {
                    lastSuccessSession = dayjs().unix();
                })
                .catch((e) => {
                    if (e.status === 403 || e.status === 401) {
                        console.log("Session invalid. Logging off.")
                        clearInterval(interval);
                        logout();
                        return;
                    }
                    if ((dayjs().unix() - lastSuccessSession) > 120) {
                        console.log("Session out of communication with server time out.  Logging off.")
                        clearInterval(interval);
                        logout();
                        return;
                    }
                    console.log("Session Check Error", e);
                });
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [sessionId]);


    const resetActivityTimer = () => {
        setLastActivity(dayjs().unix());
    };


    return (
        <>
            {children}
            <SessionWarning showWarning={showWarning} setShowWarning={setShowWarning} lastActivity={lastActivity}/>
        </>
    );
};
