import {useState} from "react";
import {Box, Stack, Tab, Tabs} from "@mui/material";
import {ExternalPublish} from "./ExternalPublish";
import {Recordings} from "./Recordings";
import {UserManager} from "./UserManager";
import {useDataContext} from "../data/Data";

export const Management = () => {
    const {userData} = useDataContext();
    const [selectedTab, setSelectedTab] = useState(!userData ? "" : userData.externalPublish ? "externalPublish" : userData.recordings ? "recordings" : userData.editUsers ? "userManager" : "");
    return (<>
        <Box sx={{background: "rgba(107,107,107,0.13)", minHeight: "calc(100vh - 465px)"}}>
            <Stack direction="column">
                <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                    <Tabs value={selectedTab} textColor="secondary" indicatorColor="secondary" onChange={(e, nv) => {
                        setSelectedTab(nv)
                    }}>
                        {userData && userData.externalPublish && <Tab label="External Publish" value="externalPublish"/>}
                        {userData && userData.recordings && <Tab label="Recordings" value="recordings"/>}
                        {userData && userData.editUsers && <Tab label="User Manager" value="userManager"/>}
                    </Tabs>
                </Box>
                {selectedTab === "externalPublish" && userData && userData.externalPublish && <ExternalPublish/>}
                {selectedTab === "recordings" && userData && userData.recordings && <Recordings/>}
                {selectedTab === "userManager" && userData && userData.editUsers && <UserManager/>}
            </Stack>
        </Box>
    </>);
}
