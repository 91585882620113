import {Box, Button, ButtonProps, Dialog, DialogContent, Paper, PaperProps, Stack, SxProps, Theme, Typography} from "@mui/material";
import {East, North, OpenInNew, OpenInNewOff, South, West, ZoomIn, ZoomOut} from "@mui/icons-material";
import {slcolor} from "../site/Theme";
import {useEffect, useState} from "react";
import {siteConfig} from "../config";
import dayjs from "dayjs";
import Draggable from "react-draggable";
import {useDataContext, useUserData} from "../data/Data";
import {useSource} from "../data/useSource";
import {useWSInterface} from "../data/WSInterface";

const controlMap: { [cmd: string]: { [key: string]: string } } = {
    up: {
        fn: "pan",
        dir: "up",
        speed: "50",
    },
    down: {
        fn: "pan",
        dir: "down",
        speed: "50",
    },
    left: {
        fn: "pan",
        dir: "left",
        speed: "80",
    },
    right: {
        fn: "pan",
        dir: "right",
        speed: "80",
    },
    zoomin: {
        fn: "zoom",
        dir: "zoomtele",
    },
    zoomout: {
        fn: "zoom",
        dir: "zoomwide",
    },
    stop: {
        fn: "pan",
        dir: "stop",
    },
    home: {
        fn: "panhome",
    },
    sethome: {
        fn: "sethome",
    },
};

interface IControlButtonProps extends ButtonProps {
    controlDir: "zoomin" | "zoomout" | "up" | "down" | "left" | "right" | "home" | "sethome"
}

const ptzQueryStr = (cmd: string, channelName: string, key: string): string => {
    const qs = new URLSearchParams();
    qs.append("name", channelName);
    qs.append("key", key);
    qs.append("ts", dayjs().unix().toString());
    const packet = controlMap[cmd];
    Object.keys(packet).forEach(key => {
        qs.append(key, packet[key]);
    });
    return qs.toString();
}

function PaperComponent(props: PaperProps) {
    return (
        <Draggable
        >
            <Paper {...props} />
        </Draggable>
    );
}

const buttonHeight = 60;
const buttonWidth = 50;

export const CameraController = () => {
    const {inControl, groups} = useDataContext();
    const {setHome, staff} = useUserData();
    const {sendChatMessage} = useWSInterface();
    const {source} = useSource(inControl?.channelName);
    const [buttonsDisabled, setButtonsDisabled] = useState(false);
    const [borderColor, setBorderColor] = useState(slcolor.orange);
    const [disabledMessage, setDisabledMessage] = useState("Click a video window to control the camera");
    const [poppedOut, setPoppedOut] = useState(false);
    const buttonSize = [50, 60];
    const buttonColor = "success";
    useEffect(() => {
        if (inControl === undefined) {
            setDisabledMessage("Click a video window to control the camera");
            setButtonsDisabled(true);
            setBorderColor(slcolor.darkgray);
            return;
        }
        if (!source?.controlable) {
            setDisabledMessage("This camera is not controllable");
            setButtonsDisabled(true);
            setBorderColor(slcolor.darkgray);
            return;
        }
        if (!source.control) {
            setDisabledMessage("You do not have permissions to control this camera");
            setButtonsDisabled(true);
            setBorderColor(slcolor.darkgray);
            return;
        }
        if (source.lockout && !staff) {
            setDisabledMessage("Camera control has been locked out by staff.");
            setButtonsDisabled(true);
            setBorderColor(slcolor.darkgray);
            return;
        }
        setButtonsDisabled(false);
        setBorderColor(slcolor.orange);
    }, [inControl, source, staff]);
    const control = (cmd: string) => {
        if (inControl === undefined) return;
        const qs = ptzQueryStr(cmd, source?.mjpegName ?? '', inControl.key);
        const uri = siteConfig.ptzControlUrl + "?" + qs;
        const myRequest = new Request(uri);
        if (cmd != "stop") {
            sendChatMessage("CAMCMD," + cmd + "," + (source?.channelName ?? ''));
        }
        fetch(myRequest).then(() => {
            return
        });
    };

    const ControlButton = ({controlDir, ...props}: IControlButtonProps) => {
        const sx: SxProps<Theme> = {width: buttonWidth, height: buttonHeight, color: "#ffffff"};
        return <Button color="success" disabled={buttonsDisabled}
                       onMouseDown={e => {
                           e.preventDefault();
                           control(controlDir);
                       }}
                       onMouseUp={e => {
                           e.preventDefault();
                           if (controlDir == "home" || controlDir == "sethome") return;
                           control("stop");
                       }}
                       onTouchStart={e => {
                           e.preventDefault();
                           control(controlDir);
                       }}
                       onTouchEnd={e => {
                           e.preventDefault();
                           if (controlDir == "home" || controlDir == "sethome") return;
                           control("stop");
                       }}
                       {...props} sx={{...sx, ...props.sx}}>{props.children}</Button>
    }
    const ZoomButton = (props: IControlButtonProps) => {
        const sx: SxProps<Theme> = {width: buttonWidth, height: "100%", border: "1px solid " + borderColor, borderRadius: 0, color: "#ffffff"};
        return <ControlButton {...props} sx={{...sx, ...props.sx}}>{props.children}</ControlButton>;
    }
    const SetHomeButton = (props: IControlButtonProps) => {
        const sx: SxProps<Theme> = {width: "100%", height: buttonHeight * .7, border: "1px solid " + borderColor, borderRadius: 0, color: "#ffffff"};
        return <ControlButton disabled={buttonsDisabled || !setHome} {...props} sx={{...sx, ...props.sx}}>{props.children}</ControlButton>;
    }
    const DisabledButton = (props: ButtonProps) => {
        const sx: SxProps<Theme> = {width: buttonWidth, height: buttonHeight};
        return <Button color="success" disabled {...props} sx={{...sx, ...props.sx}}/>
    }


    const closePopout = () => {
        return;
    };
    const controller = (<>
        <Box>
            <Stack direction="column" sx={{width: "fit-content"}}>
                <Stack direction="row" sx={{width: "fit-content"}}>
                    <Stack direction="column" sx={{border: "1px solid " + borderColor, width: "fit-content"}}>
                        <Stack direction="row">
                            <DisabledButton/>
                            <ControlButton controlDir="up"><North/></ControlButton>
                            <DisabledButton/>
                        </Stack>
                        <Stack direction="row">
                            <ControlButton controlDir="left"><West/></ControlButton>
                            <ControlButton controlDir="home">HOME</ControlButton>
                            <ControlButton controlDir="right"><East/></ControlButton>
                        </Stack>
                        <Stack direction="row">
                            <DisabledButton/>
                            <ControlButton controlDir="down"><South/></ControlButton>
                            <DisabledButton/>
                        </Stack>
                    </Stack>
                    <Stack direction="column" sx={{width: "fit-content"}}>
                        <ZoomButton controlDir="zoomin"><ZoomIn/></ZoomButton>
                        <ZoomButton controlDir="zoomout"><ZoomOut/></ZoomButton>
                    </Stack>
                </Stack>
                <SetHomeButton controlDir="sethome">SET HOME</SetHomeButton>
            </Stack>
        </Box>
        {buttonsDisabled ?
            <Stack justifyContent="center" alignItems="center" sx={{
                position: "absolute",
                zIndex: 1,
                right: 0,
                width: "260px",
                height: "225px",
                top: 0,
                background: "rgba(0,0,0,0.4)"
            }}>
                <Typography sx={{width: "137px"}}>{disabledMessage}</Typography>
            </Stack>
            : ''}
    </>)
    return (<>
        {!poppedOut && <Box sx={{position: "relative"}}>
            <OpenInNew sx={{position: "absolute", top: -21, right: 0, height: 18, cursor: "pointer"}} onClick={() => setPoppedOut(true)}/>
            {controller}
        </Box>}
        {poppedOut && <Dialog
            open={true}
            onClose={closePopout}
            PaperComponent={PaperComponent}
            hideBackdrop
            disableEnforceFocus
            disableScrollLock
            style={{pointerEvents: 'none'}}
            PaperProps={{style: {pointerEvents: 'auto'}}}
        >
            <DialogContent style={{cursor: 'move'}}>
                <Stack direction="column" alignItems="flex-end" gap={1}>
                    <OpenInNewOff sx={{height: 18, cursor: "pointer"}} onClick={() => setPoppedOut(false)}/>
                    <Box sx={{position: "relative"}}>
                        {controller}
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
        }
    </>);
};

