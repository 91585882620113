import {Box, Button, Link, Stack, Typography} from "@mui/material";
import {CamzoneLogo} from "./CamzoneLogo";
import {ClientLogo} from "./ClientLogo";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useDataContext} from "../data/Data";
import {useSpacelinkSupportChat} from "@spacelink/support-chat-customer-app";
import {config} from "../site/config";

export const Header = () => {
    const location = useLocation();
    const {sessionId, userData} = useDataContext();
    const openSLChat = useSpacelinkSupportChat(config.SPACELINK_SUPPORT_CHAT_URL, config.SPACELINK_SUPPORT_CHAT_REGKEY);
    useEffect(() => {
        console.log("LOCATION PATHNAME", location.pathname);
    }, [location.pathname]);
    return (<>
        <Stack direction="row" justifyContent="space-between" flexWrap="nowrap" alignItems="center" gap={2}
               paddingLeft={3} paddingRight={3} paddingTop={1} paddingBottom={1}
               sx={{background: "linear-gradient(0deg, rgba(18,18,18,1) 0%, rgba(18,18,18,1) 33%, rgba(51,51,51,1) 100%)"}}>
            <CamzoneLogo style={{height: 60}}/>
            <Box flexGrow={1}>
                <Stack direction="column" alignItems="flex-end">
                    {config.ENABLE_LEGACY_SUPPORT_CHAT ?
                        <Link sx={{color: "white", textAlign: "right"}} href="https://messenger.providesupport.com/messenger/1nycqc9hk9r9209lgwypbmm3kk.html" target="_blank">Support Chat - Online</Link> :
                        <Button variant="text" onClick={openSLChat}>Spacelink Support Chat</Button>}
                    {sessionId &&
                        <Typography sx={{color: "white", textAlign: "right"}}>Logged in as <Button variant="text"
                                                                                                   sx={{
                                                                                                       padding: 0,
                                                                                                       textDecoration: "underline",
                                                                                                       color: "white",
                                                                                                       textTransform: "none",
                                                                                                       fontSize: 13,
                                                                                                   }}>{userData?.name ?? ""}</Button>
                        </Typography>
                    }
                </Stack>
            </Box>
            <ClientLogo/>
        </Stack>
        <hr style={{height: 1, backgroundColor: "#666", border: "none", width: "calc(100% - 50px)"}}/>
    </>);
}

