import {createContext, Dispatch, ReactNode, SetStateAction, useContext, useState} from "react";
import {IDestinationPermissions, IGroupList, ISessionData, ISourceList, IUserGroupList, IUserList, TAlert, TChatMessage, TDestination, TExternalTarget, TUser} from "./datatypes"
import axios from "axios";
import {Session} from "./Session";
import WSInterface, {PacketActions, PacketDataTypes, PacketType, TPacket} from "./WSInterface";
import isEqual from "react-fast-compare";
import dayjs from "dayjs";


export const DataContext = createContext<IDataContext | undefined>(undefined);
export const useDataContext = (): IDataContext => {
    const context = useContext(DataContext);
    if (context === undefined) throw new Error("useDataContext must be within DataProvider");
    return context;
};
const Data = (props: { children: ReactNode }) => {
    const [sessionId, setSessionId] = useState<string>();
    const [userData, setUserData] = useState<TUser | undefined>();
    const [userGroupList, setUserGroupList] = useState<IUserGroupList>({});
    const [sources, setSources] = useState<ISourceList>({});
    const [groups, setGroups] = useState<IGroupList>({});
    const [destinationPermissions, setDestinationPermissions] = useState<IDestinationPermissions>({});
    const [channelsPoppedOut, setChannelsPoppedOut] = useState<string[]>([]);
    const [idleTimeout, setIdleTimeout] = useState(0);
    const [timeoutWarning, setTimeoutWarning] = useState(0);
    const [tokenExpire, setTokenExpire] = useState("");
    const [token, setToken] = useState("");
    const [inControl, setInControl] = useState<{ channelName: string; key: string } | undefined>();
    const [wsClose, setWsClose] = useState(false);
    const [loginErrorMessage, setLoginErrorMessage] = useState("");
    const [loginSuccessMessage, setLoginSuccessMessage] = useState("");
    const [loginInfoMessage, setLoginInfoMessage] = useState("");
    const [userList, setUserList] = useState<IUserList>({});
    const [chatMessages, setChatMessages] = useState<TChatMessage[]>([]);
    const [externalTargets, setExternalTargets] = useState<TExternalTarget[]>([]);
    const [alert, setAlert] = useState<TAlert>({message: "", level: ""});
    const [channelGoingLive, setChannelGoingLive] = useState("");

    const processSessionData = (sessionData: ISessionData) => {
        setSessionId(sessionData.sessionId);
        setToken(sessionData.token);
        axios.defaults.headers.common.Authorization = "Bearer " + sessionData.token;
        localStorage.setItem("apitoken", sessionData.token);
        updateSessionData(sessionData);
    };

    const updateSessionData = (sessionData: ISessionData) => {
        setTokenExpire(sessionData.expire);
        setIdleTimeout(sessionData.idleTimeout);
        setTimeoutWarning(sessionData.timeoutWarning);
        sessionData.user.password = "";
        setUserData(v => isEqual(v, sessionData.user) ? v : sessionData.user);
        setSources(v => isEqual(v, sessionData.sources) ? v : sessionData.sources);
        setGroups(v => isEqual(v, sessionData.groups) ? v : sessionData.groups);
        setDestinationPermissions(v => isEqual(v, sessionData.destinationPermissions) ? v : sessionData.destinationPermissions);
    };

    const setDestination = (destination: TDestination) => {
        const grps = {...groups};
        grps[destination.groupName][destination.destinationChannel] = destination;
        setGroups(grps);
    }

    const clearSession = () => {
        localStorage.removeItem("apitoken");
        if (axios.defaults.headers.common.Authorization !== undefined) {
            delete axios.defaults.headers.common.Authorization;
        }
        setSessionId("");
        setToken("");
        setUserData(undefined);
        setSources({});
        setIdleTimeout(0);
        setTokenExpire("");
        setGroups({});
        setDestinationPermissions({});
    };

    const updateUserGroupList = (userGroupListData: IUserGroupList) => {
        setUserGroupList(v => isEqual(v, userGroupListData) ? v : userGroupListData);
    }

    const updateUserList = (userListData: TUser[]) => {
        const ul: IUserList = {};
        if (!userListData) {
            setUserList(ul)
            return;
        }
        userListData.forEach(v => ul[v.userId] = v);
        setUserList(v => isEqual(v, ul) ? v : ul);
    };

    const updateChatData = (chatData: TChatMessage[]) => {
        chatData = chatData.map(v => {
            v.message = v.message.replace(/(<([^>]+)>)/ig, '');
            return v;
        })
        chatData.sort((a, b): number => {
            const ats = dayjs(a.created);
            if (ats.isSame(b.created)) return 0
            return ats.isAfter(b.created) ? 1 : -1;
        });
        setChatMessages(v => isEqual(v, chatData) ? v : chatData);
    }

    const appendChatMessage = (chatMessage: TChatMessage) => {
        chatMessage.message = chatMessage.message.replace(/(<([^>]+)>)/ig, '');
        setChatMessages(cms => [...cms, chatMessage]);
    };

    const updateExternalTargets = (targetData: TExternalTarget[]) => {
        setExternalTargets(v => isEqual(v, targetData) ? v : targetData);
    };

    const updateAlert = (alertData: TAlert) => {
        setAlert(v => isEqual(v, alertData) ? v : alertData);
    };

    const routePacket = (packet: TPacket) => {
        if (packet.type == PacketType.DATAPACKET) {
            switch (packet.dataType) {
                case PacketDataTypes.SESSIONDATA:
                    updateSessionData(packet.data);
                    break;
                case PacketDataTypes.USERLISTDATA:
                    updateUserList(packet.data);
                    break;
                case PacketDataTypes.USERGROUPLISTDATA:
                    updateUserGroupList(packet.data);
                    break;
                case PacketDataTypes.CHATMESSAGESDATA:
                    updateChatData(packet.data);
                    break;
                case PacketDataTypes.EXTERNALPUBLISHDATA:
                    updateExternalTargets(packet.data);
                    break;
                case PacketDataTypes.ALERTUPDATE:
                    updateAlert(packet.data);
                    break;
            }
        }
        if (packet.type == PacketType.ACTIONPACKET) {
            switch (packet.action) {
                case PacketActions.CLOSESESSION:
                    setWsClose(true);
                    setLoginInfoMessage(packet.actionData.message);
                    clearSession();
                    break;
                case PacketActions.NEWCHATMESSAGE:
                    appendChatMessage(packet.actionData as TChatMessage)
                    break;
            }
        }
    }

    return (
        <>
            <DataContext.Provider
                value={{
                    sessionId,
                    setSessionId,
                    userData,
                    sources,
                    groups,
                    userGroupList,
                    destinationPermissions,
                    setDestination,
                    idleTimeout,
                    timeoutWarning,
                    token,
                    tokenExpire,
                    processSessionData,
                    clearSession,
                    inControl,
                    setInControl,
                    channelsPoppedOut,
                    setChannelsPoppedOut,
                    routePacket,
                    loginSuccessMessage,
                    loginInfoMessage,
                    loginErrorMessage,
                    setLoginInfoMessage,
                    setLoginErrorMessage,
                    setLoginSuccessMessage,
                    userList,
                    chatMessages,
                    externalTargets,
                    alert,
                    channelGoingLive,
                    setChannelGoingLive,
                }}>
                <WSInterface wsClose={wsClose} setWsClose={setWsClose}>
                    <Session>
                        {props.children}
                    </Session>
                </WSInterface>
            </DataContext.Provider>
        </>
    );
};

export interface IDataContext {
    sessionId: string | undefined;
    setSessionId: Dispatch<SetStateAction<string | undefined>>;
    userData: TUser | undefined;
    sources: ISourceList;
    groups: IGroupList;
    userGroupList: IUserGroupList;
    destinationPermissions: IDestinationPermissions;
    setDestination: (destination: TDestination) => void;
    idleTimeout: number;
    timeoutWarning: number;
    token: string;
    tokenExpire: string;
    processSessionData: (data: ISessionData) => void;
    clearSession: () => void;
    inControl: { channelName: string, key: string } | undefined;
    setInControl: Dispatch<SetStateAction<{ channelName: string, key: string } | undefined>>;
    channelsPoppedOut: string[];
    setChannelsPoppedOut: Dispatch<SetStateAction<string[]>>
    routePacket: (packet: TPacket) => void;
    loginErrorMessage: string;
    setLoginErrorMessage: Dispatch<SetStateAction<string>>;
    loginSuccessMessage: string;
    setLoginSuccessMessage: Dispatch<SetStateAction<string>>;
    loginInfoMessage: string;
    setLoginInfoMessage: Dispatch<SetStateAction<string>>;
    userList: IUserList;
    chatMessages: TChatMessage[];
    externalTargets: TExternalTarget[];
    alert: TAlert;
    channelGoingLive: string;
    setChannelGoingLive: Dispatch<SetStateAction<string>>;
}

export default Data;

export const useUserData = (): TUser => {
    const {userData} = useDataContext();
    if (userData === undefined) throw new Error("useUserData must be accessed after successful login")
    return userData;
}
